<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item>
        <span>我的位置：</span>
        <span @click="chooseMenu(1)" style="cursor: pointer" class="clickable">
          个人中心</span
        >
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span @click="chooseMenu(7)" style="cursor: pointer" class="clickable"
          >云服务器</span
        >
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="cursor: pointer" class="clickable"
          >{{ region_area }}服务器</span
        >
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-table :data="tableData" v-loading="loading" style="width: 100%">
      <el-table-column prop="id" label="ID" width="50"></el-table-column>
      <el-table-column prop="orderno" label="订单号"> </el-table-column>
      <el-table-column prop="region" label="服务器区域"> </el-table-column>
      <el-table-column prop="amount" label="订单金额"> </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" type="danger">已到期</el-tag>
          <el-tag v-if="scope.row.status === 1" type="success">未到期</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="订单描述">
        <template slot-scope="scope">
          <el-button type="text" @click="checkOrderInfo(scope.row)"
            >查看服务器配置</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createtime) }}
        </template>
      </el-table-column>
      <el-table-column prop="expired" label="到期时间">
        <template slot-scope="scope">
          <span v-if="scope.row.expired">{{
            formatDate(scope.row.expired)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="330">
        <template slot-scope="scope">
          <!-- <el-button
            type="warning"
            v-if="scope.row.status === 0"
            @click="openRenewalServerDialog(scope.row)"
            >支付</el-button
          >
          <el-button type="warning" disabled v-if="scope.row.status === 1"
            >支付</el-button
          > -->
          <el-button v-if="scope.row.status === 1" type="danger"
            >停止</el-button
          >
          <el-button v-else disabled type="danger">停止</el-button>
          <el-button v-if="scope.row.status === 1" type="primary"
            >重启</el-button
          >
          <el-button v-else disabled type="primary">重启</el-button>
          <el-button v-if="scope.row.status === 1" type="success"
            >启动</el-button
          >
          <el-button v-else disabled type="success">启动</el-button>
          <el-popconfirm
            v-if="scope.row.status === 1"
            title="确定续费该服务器吗？"
            @confirm="renewalCloudServers(scope.row)"
          >
            <el-button slot="reference" type="warning" style="margin-left: 10px"
              >续费</el-button
            >
          </el-popconfirm>
          <el-button v-else disabled type="warning">续费</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 订单描述 -->
    <el-dialog title="查看订单描述" :visible.sync="orderInfoDialog" width="30%">
      <el-descriptions :title="serverInfo.orderno + ' 服务器信息'">
        <el-descriptions-item label="服务器用户名">{{
          serverInfo.username
        }}</el-descriptions-item>
        <el-descriptions-item label="服务器区域">{{
          serverInfo.region
        }}</el-descriptions-item>
        <el-descriptions-item label="内存大小(M)">{{
          serverInfo.ram_size
        }}</el-descriptions-item>
        <el-descriptions-item label="CPU核心数">{{
          serverInfo.cpu_core
        }}</el-descriptions-item>
        <el-descriptions-item label="网络大小(M)">{{
          serverInfo.network_size
        }}</el-descriptions-item>
        <el-descriptions-item label="硬盘大小(GB)">{{
          serverInfo.disk_size
        }}</el-descriptions-item>
        <el-descriptions-item label="操作系统类型名称">{{
          serverInfo.system_type
        }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { getServerListForArea, renewalCloudServer } from "@/api/api";

import moment from "moment";

export default {
  props: {
    region_area: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      tableData: [],
      loading: false,
      time: "",
      total: "",
      serverInfo: [],
      orderInfoDialog: false,
    };
  },
  methods: {
    renewalCloudServers(row) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      renewalCloudServer(token, row.orderno, uid).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("续费成功！");
          this.getServerListForAreas();
          loading.close();
          return;
        }
        this.$message.error("续费失败！" + res.data.msg);
        loading.close();
      });
    },
    checkOrderInfo(row) {
      if (row.status === 0) {
        this.$message.error("请先支付订单再查看服务器信息！");
        return;
      }
      this.serverInfo = row;
      this.orderInfoDialog = true;
    },

    handlePageChange(page) {
      this.page = page;
      this.getServerListForAreas(); // 请求新页面的数据
    },
    getServerListForAreas() {
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      this.loading = true;
      getServerListForArea(
        token,
        uid,
        this.page,
        this.pageSize,
        this.region_area
      ).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error("获取需求信息失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    chooseMenu(data) {
      this.$emit("custom-event", data);
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {
    this.getServerListForAreas();
  },
};
</script>

<style></style>
