<template>
  <div>
    <div class="customTitle">
      <i class="fa fa-home"></i>
      <span style="margin-left: 10px">主页</span>
      <i class="fa fa-angle-right" style="margin-left: 10px"></i>
      <span style="margin-left: 10px">定制服务</span>
    </div>
    <!-- 定制服务部分 -->
    <div class="customService">
      <h3 style="margin: 0 0 0 10px">
        定制服务
        <span style="float: right; font-weight: 300; margin: 0 10px 0 0"
          >更多>></span
        >
      </h3>
      <div style="border-bottom: 1px solid #dcdfe6; margin: 10px 0 0 0" />
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :lg="6"
          :offset="0"
          v-for="item in about"
          :key="item.id"
          style="padding: 20px"
        >
          <el-popover
            placement="top-start"
            trigger="hover"
            :content="item.content"
            :disabled="item.hidePopover"
          >
            <el-row
              slot="reference"
              gutter="40"
              @click.native="handleClick(item.dialog)"
            >
              <el-col :xs="3" :lg="6">
                <img
                  :src="item.image"
                  alt="image"
                  style="width: 40px; height: 40px; border-radius: 100px"
                />
              </el-col>
              <el-col :xs="21" :lg="18" style="line-height: 15px">
                <p
                  style="
                    font-weight: bolder;
                    margin-top: 0px;
                    margin-bottom: 5px;
                  "
                >
                  {{ item.title }}
                  <i
                    class="fa fa-angle-right"
                    style="
                      float: right;
                      color: #abb0bd;
                      border: 1px #abb0bd solid;
                      padding: 0px 5px;
                      border-radius: 100px;
                    "
                  ></i>
                </p>
                <p class="customInfo">
                  {{ item.info }}
                </p>
              </el-col>
            </el-row>
          </el-popover>
        </el-col>
      </el-row>
    </div>
    <!-- 流程部分 -->
    <div class="customList">
      <h1>极速棋牌定制服务流程</h1>
      <p style="color: #666666">
        极速棋牌拥有多年行业经验，能够帮助客户快速部署，二次开发棋牌项目，有效帮助客户降低成本投入，不论有无经验，无障碍对接，极速实现客户所想。
      </p>
      <el-row :gutter="20" class="hide">
        <el-col :span="5" :offset="0">
          <i class="el-icon-s-order"></i>
        </el-col>
        <el-col :span="5" :offset="0">
          <i class="el-icon-edit"></i>
        </el-col>
        <el-col :span="5" :offset="0">
          <i class="el-icon-s-claim"></i>
        </el-col>
        <el-col :span="5" :offset="0">
          <i class="el-icon-s-platform"></i>
        </el-col>
        <el-col :span="5" :offset="0">
          <i class="el-icon-document-checked"></i>
        </el-col>
      </el-row>
      <el-row gutter="20" class="hide">
        <el-col span="5">
          <h3>需求分析</h3>
          <p>提交后，客服会主动联系你，了解基本需求</p>
        </el-col>
        <el-col span="5">
          <h3>方案设计</h3>
          <p>极速与客户沟通细节，并提供定制方案</p></el-col
        >
        <el-col span="5">
          <h3>开发调试</h3>
          <p>确定方案与费用后，极速签订合同</p></el-col
        >
        <el-col span="5">
          <h3>需求分析</h3>
          <p>极速根据方案内容开发调试安装产品、调试系统</p></el-col
        >
        <el-col span="5">
          <h3>项目验收</h3>
          <p>开发完成后，配合你现场验收，确保所有功能符合要求</p></el-col
        >
      </el-row>
      <el-row :gutter="20" class="phoneList">
        <el-col :span="24" :offset="0">
          <i class="el-icon-s-order phoneListi"></i>
          <h3>需求分析</h3>
          <p>提交后，客服会主动联系你，了解基本需求</p>
        </el-col>
        <el-col :span="24" :offset="0">
          <i class="el-icon-edit phoneListi"></i>
          <h3>方案设计</h3>
          <p>极速与客户沟通细节，并提供定制方案</p>
        </el-col>
        <el-col :span="24" :offset="0">
          <i class="el-icon-s-claim phoneListi"></i>
          <h3>开发调试</h3>
          <p>确定方案与费用后，极速签订合同</p>
        </el-col>
        <el-col :span="24" :offset="0">
          <i class="el-icon-s-platform phoneListi"></i>
          <h3>需求分析</h3>
          <p>极速根据方案内容开发调试安装产品、调试系统</p>
        </el-col>
        <el-col :span="24" :offset="0">
          <i class="el-icon-document-checked phoneListi"></i>
          <h3>项目验收</h3>
          <p>开发完成后，配合你现场验收，确保所有功能符合要求</p>
        </el-col>
      </el-row>
    </div>
    <!-- 游戏换皮服务弹窗 -->
    <el-dialog
      title="游戏换皮服务"
      :visible.sync="gameChangeDialog"
      width="30%"
    >
      <el-form>
        <el-form-item label="您的产品:">
          <el-select v-model="change.game" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <img :src="item.img" alt="" style="float: left; width: 30px" />
              <!-- <span style="float: left">{{ item.label }}</span> -->
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.label
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="换皮方式:">
          <el-radio-group v-model="change.type" size="small">
            <el-tooltip
              class="item"
              effect="dark"
              content="由UI按照您的想法对界面进行全新设计，价格相对较高"
              placement="top"
            >
              <el-radio label="1" border>全新设计</el-radio>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="模仿其他产品进行设计，性价比相对较高"
              placement="top"
            >
              <el-radio label="2" border>模仿产品</el-radio>
            </el-tooltip>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="您的联系方式(QQ或Telegram):">
          <el-input v-model="change.contact"></el-input>
        </el-form-item>
        <el-form-item label="您的需求描述:">
          <el-input
            v-model="change.info"
            type="textarea"
            :rows="4"
            placeholder="请输入您的具体需求"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="附件:">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="handleAvatarUpload"
            :http-request="handleCustomUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" center>
        <button type="primary" @click="gamechanges()">获取报价</button>
      </span>
    </el-dialog>
    <!-- 二次开发服务弹窗 -->
    <el-dialog
      title="二次开发服务"
      :visible.sync="secondChangeDialog"
      width="30%"
    >
      <el-form>
        <el-form-item label="您的产品:">
          <el-select v-model="second.game" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <img :src="item.img" alt="" style="float: left; width: 30px" />
              <!-- <span style="float: left">{{ item.label }}</span> -->
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.label
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="您的联系方式(QQ或Telegram):">
          <el-input v-model="second.contact"></el-input>
        </el-form-item>
        <el-form-item label="您的需求描述:">
          <el-input
            v-model="second.info"
            type="textarea"
            :rows="4"
            placeholder="请输入您的具体需求"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="附件:">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="handleAvatarUpload"
            :http-request="handleCustomUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" center>
        <button type="primary" @click="secondChanges()">获取报价</button>
      </span>
    </el-dialog>
    <!-- 个性化定制服务弹窗 -->
    <el-dialog title="个性化定制服务" :visible.sync="customDialog" width="30%">
      <p>如果您需要更多个性化定制服务，请与我们商务详谈</p>
      <div style="text-align: center">
        <a target="_blank" href="https://tool.gljlw.com/qq/?qq=3471043983">
          <img
            src="@/assets/QQ.png"
            alt=""
            style="
              width: 60px;
              height: 60px;
              background-color: white;
              border-radius: 100px;
              padding: 10px;
            "
          />
        </a>
        <a target="_blank" href="https://t.me/yliang103">
          <img
            src="@/assets/telegram.png"
            alt=""
            style="
              width: 60px;
              height: 60px;
              margin-top: 20px;
              margin-left: 50px;
            "
          />
        </a>
      </div>
    </el-dialog>
    <!-- 外包维护包月弹窗 -->
    <el-dialog title="外包维护包月服务" :visible.sync="fixedDialog" width="30%">
      <el-form>
        <el-form-item label="您的产品:">
          <el-select v-model="fixed.game" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <img :src="item.img" alt="" style="float: left; width: 30px" />
              <!-- <span style="float: left">{{ item.label }}</span> -->
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.label
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="技术要求">
          <div style="display: flex">
            <el-checkbox-group
              v-for="item in yonoTecPrice"
              :key="item.id"
              v-model="checkList"
              style="margin: 0 10px"
            >
              <el-checkbox :label="item.title"></el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>
        <el-form-item label="请选择购买服务时长">
          <br />
          <el-row :gutter="20">
            <el-col
              :xs="24"
              :lg="6"
              :offset="0"
              v-for="item in time"
              :key="item.id"
              style="margin: 10px 0"
            >
              <div
                @click="
                  chooseServiceTime(item.time, item.discountValue, item.month)
                "
                :style="{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#ffeae6',
                  borderRadius: '10px',
                  border:
                    serviceTime === item.time
                      ? '1px #ff0000 solid'
                      : '1px #666666 solid',
                  boxShadow:
                    serviceTime === item.time
                      ? '0 0 20px #ff0000'
                      : '0 0 0px #909399',
                  transition: 'all 0.3s ease',
                }"
              >
                <i
                  class="el-icon-caret-right"
                  style="color: #1f62b5; font-size: 15px"
                ></i>
                <p style="font-size: 15px; margin: 8px">
                  {{ item.time }}
                </p>

                <div
                  v-if="item.discount != '无折扣'"
                  style="
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 0;
                    height: 0;
                    border-top: 50px solid red;
                    border-left: 50px solid transparent;
                    border-radius: 0 10px 0 0;
                  "
                >
                  <span
                    style="
                      position: absolute;
                      top: -40px;
                      right: -30px;
                      left: -30px;
                      transform: rotate(45deg);
                      color: white;
                      font-size: 14px;
                    "
                  >
                    {{ item.discount }}
                  </span>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- <el-form-item label="您的联系方式(QQ或Telegram):">
          <el-input v-model="fixed.contact"></el-input>
        </el-form-item>
        <el-form-item label="需求描述:">
          <el-input
            v-model="fixed.info"
            type="textarea"
            :rows="4"
            placeholder="请输入您的具体需求"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="附件:">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="handleAvatarUpload"
            :http-request="handleCustomUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item> -->
      </el-form>
      <div style="float: right">
        <p v-if="discountValue > 0">
          应付价格：<span style="color: red; font-weight: bolder">{{
            totalValue * month
          }}</span>
          优惠金额：<span style="color: red; font-weight: bolder">
            {{ totalValue * month * discountValue }}
          </span>
          实际支付金额：<span style="color: red; font-weight: bolder">
            {{ totalValue * month - totalValue * month * discountValue }}
          </span>
        </p>
        <p v-else>
          应付价格：<span style="color: red; font-weight: bolder">{{
            totalValue
          }}</span>
        </p>
      </div>
      <span slot="footer" class="dialog-footer" center>
        <button @click="fixedGames()">立即购买</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fixedGame,
  gamechange,
  getYonoTecPrice,
  secondChange,
} from "@/api/api";
export default {
  data() {
    return {
      change: [],
      second: [],
      fixed: [],
      checkList: [],
      gameChangeDialog: false,
      secondChangeDialog: false,
      customDialog: false,
      fixedDialog: false,
      about: [
        {
          id: 1,
          image: "https://api.jisuqipai.online/uploads/custom1.png",
          title: "游戏换皮服务",
          info: "不想和别人一样？有自己的独特审美？价格优惠，都可以定制游戏换皮服务。",
          content: "全新设计  模仿产品",
          hidePopover: true,
          dialog: "gameChangeDialog",
        },
        {
          id: 2,
          image: "https://api.jisuqipai.online/uploads/custom2.png",
          title: "二次开发服务",
          info: "游戏功能满足不了现有需求？跟随需求，二次开发/修改更多功能。",
          content: "123test",
          hidePopover: true,
          dialog: "secondChangeDialog",
        },
        {
          id: 3,
          image: "https://api.jisuqipai.online/uploads/custom3.png",
          title: "外包维护包月服务",
          info: "无需招人就可以轻松解决开发/BUG修复问题，省时省心省力，还省下了高昂的技术人力成本和场地成本。",
          url: "https://www.baidu.com",
          content: "123test",
          hidePopover: true,
          dialog: "fixedDialog",
        },
        {
          id: 4,
          image: "https://api.jisuqipai.online/uploads/custom4.png",
          title: "个性化定制服务",
          info: "前面满足不了需求，与商务洽谈，制定更多个性化的服务。",
          url: "https://www.baidu.com",
          content: "123test",
          hidePopover: true,
          dialog: "customDialog",
        },
      ],
      options: [
        {
          value: "YonoGames",
          label: "YonoGames",
          img: require("@/assets/yonodoc/ynlogo.png"),
        },
        {
          value: "CokeJogo",
          label: "CokeJogo",
          img: require("@/assets/yonodoc/cokelogo.jpg"),
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      imageUrl: "", // 用于显示base64图片
      base64Image: "", // 存储Base64格式图片
      uid: "",
      time: [
        {
          id: 1,
          time: "1个月",
          discount: "无折扣",
          discountValue: 0,
          month: 1,
        },
        {
          id: 2,
          time: "3个月",
          discount: "9折",
          discountValue: 0.1,
          month: 3,
        },
        {
          id: 3,
          time: "6个月",
          discount: "8折",
          discountValue: 0.2,
          month: 6,
        },
        {
          id: 4,
          time: "12个月",
          discount: "7折",
          discountValue: 0.3,
          month: 12,
        },
      ],
      serviceTime: "",
      yonoTecPrice: [],
      totalValue: 0,
      discountValue: 0,
      month: 1,
    };
  },
  methods: {
    calculateTotalValue(selectedTitles) {
      // 根据选中的 title 计算对应 value 的总和
      return this.yonoTecPrice
        .filter((item) => selectedTitles.includes(item.title))
        .reduce((sum, item) => sum + parseFloat(item.value), 0);
    },
    getYonoTecPrices() {
      getYonoTecPrice().then((res) => {
        if (res.data.code === 200) {
          this.yonoTecPrice = res.data.data;
        } else {
          this.$message.error("获取技术报价失败！" + res.data.msg);
        }
      });
    },
    chooseServiceTime(time, discount, month) {
      this.serviceTime = time;
      this.discountValue = discount;
      this.month = month;
    },
    fixedGames() {
      if (
        this.fixed.game && // 确保 this.fixed.game 存在
        this.checkList.length > 0 && // 检查 checkList 是否非空
        this.totalValue > 0 && // 检查 totalValue 是否大于 0
        this.serviceTime // 确保 serviceTime 非空
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在提交",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const uid = localStorage.getItem("uid");
        fixedGame(
          uid,
          this.fixed.game,
          this.checkList,
          this.serviceTime,
          this.totalValue * this.month,
          this.discountValue
        )
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success("提交成功！");
              this.fixedDialog = false;
              loading.close();
            } else {
              this.$message.error(res.data.msg);
              loading.close();
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg);
            loading.close();
          });
      } else {
        this.$message.error("请填写完整信息");
      }
    },
    gamechanges() {
      if (
        (this.change.game,
        this.change.type,
        this.change.contact,
        this.change.info,
        this.base64Image)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在提交",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        gamechange(
          this.change.game,
          this.change.type,
          this.change.contact,
          this.change.info,
          this.base64Image,
          this.uid
        )
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success("您的需求我们已知晓，稍后将会有商务联系您");
              this.gameChangeDialog = false;
              loading.close();
            } else {
              this.$message.error(res.data.msg);
              loading.close();
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg);
            loading.close();
          });
      } else {
        this.$message.error("请填写完整信息");
      }
    },
    secondChanges() {
      if ((this.second.game, this.second.contact, this.second.info)) {
        const loading = this.$loading({
          lock: true,
          text: "正在提交",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        secondChange(
          this.second.game,
          this.second.contact,
          this.second.info,
          this.base64Image,
          this.uid
        )
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success("您的需求我们已知晓，稍后将会有商务联系您");
              this.secondChangeDialog = false;
              loading.close();
            } else {
              this.$message.error(res.data.msg);
              loading.close();
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg);
            loading.close();
          });
      } else {
        this.$message.error("请填写完整信息");
      }
    },
    handleClick(dialogName) {
      console.log("传入的弹窗名称为" + dialogName);
      console.log(this[dialogName]); // 确认对象中的值
      this.$set(this, dialogName, true);
    },
    // 自定义上传行为，不实际上传，先进行Base64处理
    handleAvatarUpload(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // 将文件读取为Base64
      reader.onload = () => {
        this.imageUrl = reader.result; // 显示Base64图片
        this.base64Image = reader.result; // 存储Base64字符串以备上传
      };
      return false; // 阻止el-upload的默认上传行为
    },
  },
  watch: {
    checkList: {
      handler(newCheckList) {
        this.totalValue = this.calculateTotalValue(newCheckList);
      },
      immediate: true, // 初始时执行一次
    },
  },
  created() {
    this.uid = localStorage.getItem("uid");
    console.log(this.uid);
    if (this.uid) {
      console.log("用户已登录");
      this.getYonoTecPrices();
    } else {
      console.log("用户未登录");
    }
  },
};
</script>

<style>
.buyBtn {
  font-size: 12px;
  color: #abb0bd;
  margin: 5px 0px;
  font-weight: bolder;
  border: 1px #abb0bd solid;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.buyBtn:hover {
  background-color: #abb0bd;
  color: white;
}

.el-col-5 {
  width: 20%;
}

.el-col-5 > i {
  font-size: 40px;
  background-color: #ffa94f;
  color: white;
  padding: 20px;
  border-radius: 100px;
}

.el-col-5 > p {
  font-size: 13px;
}

.customTitle {
  color: #666666;
  padding: 10px 150px 0px 150px;
}

.customService {
  background-color: white;
  margin: 20px 150px 0 150px;
  padding: 15px;
  color: #666666;
}

.customInfo {
  color: #abb0bd;
  font-size: 12px;
  width: 150px;
  max-height: 3em;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.customList {
  background-color: white;
  padding: 20px;
  margin: 20px 150px 0 150px;
  text-align: center;
}

.phoneList {
  display: none;
}

@media screen and (max-width: 768px) {
  .customTitle {
    display: none;
  }
  .customService {
    margin: 0;
  }
  .customInfo {
    width: 100%;
  }
  .customList {
    margin: 0;
  }
  .hide {
    display: none;
  }
  .phoneList {
    display: contents;
  }
  .phoneListi {
    font-size: 40px;
    background-color: #ffa94f;
    color: white;
    padding: 20px;
    border-radius: 100px;
  }
}

.el-radio.is-bordered.is-checked {
  border-color: #cb1414 !important;
}

.el-textarea__inner:hover {
  border-color: #cb1414 !important;
}

.el-textarea__inner:focus {
  border-color: #cb1414 !important;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner {
  border-color: #cb1414 !important;
}

.el-select .el-input__inner:focus {
  border-color: #cb1414 !important;
}

.el-select-dropdown__item.selected {
  color: #cb1414;
  font-weight: 700;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #cb1414;
  transition: all 0.3s ease;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
