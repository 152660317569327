import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.jisuqipai.online/",
});

// 登录接口
export const Login = (account, password) => {
  return axiosInstance.post("/api/user/login", {
    account: account,
    password: password,
  });
};

// 注册接口
export const Register = (username, password, email, captcha) => {
  return axiosInstance.post("/api/user/register", {
    username: username,
    password: password,
    email: email,
    captcha: captcha,
  });
};

// usdt支付接口
export const usdtPay = (amount, token) => {
  return axiosInstance.post(
    "/api/payment/usdt",
    {
      amount: amount,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取文章列表
export const getArticles = () => {
  return axiosInstance.get("/api/index/articles");
};

// 获取对应id文章信息
export const getArticleDetails = (id) => {
  return axiosInstance.post("/api/index/articleDetails", {
    id: id,
  });
};

// 获取邮箱验证码
export const getMailCaptcha = (email, event) => {
  return axiosInstance.post("/api/ems/send", {
    email: email,
    event: event,
  });
};

// 通过邮箱找回密码
export const resetpwd = (email, newpassword, captcha) => {
  return axiosInstance.post("/api/user/resetpwd", {
    email: email,
    newpassword: newpassword,
    captcha: captcha,
  });
};

// YonoGames上分体验
export const YonoUpscore = (uid, coin, token) => {
  return axiosInstance.post(
    "/api/demo/YonoUpscore",
    {
      uid: uid,
      coin: coin,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 日活月活访问统计
export const statistics = () => {
  return axiosInstance.post("/api/index/statistics");
};

// 代理登录接口
export const AgentLogin = (account, password, quick) => {
  return axiosInstance.post("/api/agent/login", {
    account: account,
    password: password,
    quick: quick,
  });
};

// 代理创建用户
export const createAgent = (token, username) => {
  return axiosInstance.post(
    "/api/agent/createAgent",
    {
      username: username,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 代理重置密码
export const resetPwd = (token, username, oldPassword, newPassword) => {
  return axiosInstance.post(
    "/api/agent/resetPwd",
    {
      username: username,
      oldPassword: oldPassword,
      newPassword: newPassword,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 通过代理链接创建用户
export const registerForUid = (
  username,
  password,
  email,
  captcha,
  inviteid
) => {
  return axiosInstance.post("/api/user/registerForUid", {
    username: username,
    password: password,
    email: email,
    captcha: captcha,
    inviteid: inviteid,
  });
};

// 获取当前代理用户直接客户数量 间接客户数量 今日新增客户 今日佣金 当月新增直接客户 当月佣金
export const getAgentUserInfo = (token, uid) => {
  return axiosInstance.post(
    "/api/agent/getAgentUserInfo",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 代理获取当前uid下所有客户信息
export const getClientInfo = (token, userid, uid, time) => {
  return axiosInstance.post(
    "/api/agent/getClientInfo",
    {
      userid: userid,
      uid: uid,
      time: time,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取所有充值记录
export const getrechargelist = (date) => {
  return axiosInstance.post("/api/agent/getrechargelist", {
    date: date,
  });
};

// 代理升级
export const agentUpgrade = (token, uid, level, type) => {
  return axiosInstance.post("/api/agent/agentUpgrade", {
    token: token,
    uid: uid,
    level: level,
    type: type,
  });
};

// 代理获取当前用户的USDT余额
export const getAgentUSDT = (uid) => {
  return axiosInstance.post("/api/agent/getAgentUSDT", {
    uid: uid,
  });
};

// 提交用户定制信息(游戏换皮)
export const gamechange = (game, type, contact, info, img, uid) => {
  return axiosInstance.post("/api/index/gamechange", {
    game: game,
    type: type,
    contact: contact,
    info: info,
    img: img,
    uid: uid,
  });
};

// 提交用户定制信息(二次开发)
export const secondChange = (game, contact, info, img, uid) => {
  return axiosInstance.post("/api/index/secondChange", {
    game: game,
    contact: contact,
    info: info,
    img: img,
    uid: uid,
  });
};

// 提交用户定制信息(外包维护)
export const fixedGame = (uid, game, tec, time, amount, discount) => {
  return axiosInstance.post("/api/index/fixedGame", {
    uid: uid,
    game: game,
    tec: tec,
    time: time,
    amount: amount,
    discount: discount,
  });
};

// 通过UID获取当前代理的telegram与qq联系信息
export const getContact = (uid) => {
  return axiosInstance.post("/api/agent/getContact", {
    uid: uid,
  });
};

// 通过UID完善当前代理的telegram与qq联系信息
export const updateContact = (uid, telegram, qq) => {
  return axiosInstance.post("/api/agent/updateContact", {
    uid: uid,
    telegram: telegram,
    qq: qq,
  });
};

// 检查当前用户是否存在上级 如果有返回上级telegram和qq
export const checkUpgrade = (username) => {
  return axiosInstance.post("/api/index/checkUpgrade", {
    username: username,
  });
};

// 根据当前用户名获取对应用户uid
export const getUserUid = (token, username) => {
  return axiosInstance.post(
    "/api/user/getUserUid",
    {
      username: username,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取游戏换皮数据
export const getGameChange = (uid) => {
  return axiosInstance.post("/api/agent/getGameChange", {
    uid: uid,
  });
};

// 完善游戏换皮报价
export const fillPrice = (id, price) => {
  return axiosInstance.post("/api/agent/fillPrice", {
    id: id,
    price: price,
  });
};

// 获取二次开发数据
export const getSecondDev = (uid) => {
  return axiosInstance.post("/api/agent/getSecondDev", {
    uid: uid,
  });
};

// 填写二次开发报价
export const fillSecondPrice = (id, price) => {
  return axiosInstance.post("/api/agent/fillSecondPrice", {
    id: id,
    price: price,
  });
};

// 获取外包维护数据
export const getOutService = (uid) => {
  return axiosInstance.post("/api/agent/getOutService", {
    uid: uid,
  });
};

// 填写外包维护报价
export const fillOutPrice = (id, price) => {
  return axiosInstance.post("/api/agent/fillOutPrice", {
    id: id,
    price: price,
  });
};

// 服务器下单
export const serverOrder = (
  token,
  uid,
  region,
  memory,
  cpu,
  network,
  disk,
  system,
  username,
  password,
  amount,
  time
) => {
  return axiosInstance.post(
    "/api/user/serverOrder",
    {
      uid: uid,
      region: region,
      memory: memory,
      cpu: cpu,
      network: network,
      disk: disk,
      system: system,
      username: username,
      password: password,
      amount: amount,
      time: time,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 根据当前用户名获取对应用户uid
export const index = (token) => {
  return axiosInstance.post(
    "/api/user/index",
    {},
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取极速学院所有分类
export const getSchoolType = () => {
  return axiosInstance.post("/api/school/getSchoolType");
};

// 根据分类名称获取极速学院视频
export const getSchoolVideoList = (type) => {
  return axiosInstance.post("/api/school/getSchoolVideoList", {
    type: type,
  });
};

// 举报
export const report = (token, uid, telegram, wechat, qq, content, img) => {
  return axiosInstance.post(
    "/api/user/report",
    {
      uid: uid,
      telegram: telegram,
      wechat: wechat,
      qq: qq,
      content: content,
      img: img,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 图片上传
export const uploadImg = (img, config) => {
  return axiosInstance.post(
    "/api/user/uploadImg",
    {
      img: img,
    },
    config
  );
};

// 举报
export const getAmount = (token, uid) => {
  return axiosInstance.post(
    "/api/user/getAmount",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 设置新头像
export const setNewAvatar = (token, uid, avatar) => {
  return axiosInstance.post(
    "/api/user/setNewAvatar",
    {
      uid: uid,
      avatar: avatar,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取USDT最新汇率
export const getUSDTrate = () => {
  return axiosInstance.post("/api/user/getUSDTrate", {});
};

// 检查当前用户状态
export const checkUserStatus = (token, uid) => {
  return axiosInstance.post(
    "/api/user/checkUserStatus",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 根据视频id获取极速学院视频
export const getSchoolVideo = (id) => {
  return axiosInstance.post("/api/school/getSchoolVideo", {
    id: id,
  });
};

// 根据视频id获取极速学院视频信息
export const getSchoolVideoInfomation = (id) => {
  return axiosInstance.post("/api/school/getSchoolVideos", {
    id: id,
  });
};

// 获取活动
export const getActivity = () => {
  return axiosInstance.post("/api/activity/getActivity", {});
};

// 领取优惠卷
export const takeRedpack = (uid, array) => {
  return axiosInstance.post("/api/redpack/takeRedpack", {
    uid: uid,
    array: array,
  });
};

// 通过UID查询用户所有优惠卷
export const getActivityForUid = (uid) => {
  return axiosInstance.post("/api/activity/getActivityForUid", {
    uid: uid,
  });
};

// 修改邮箱
export const changeemail = (token, email, captcha) => {
  return axiosInstance.post(
    "/api/user/changeemail",
    {
      email: email,
      captcha: captcha,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 短信发送
export const send = (mobile, event) => {
  return axiosInstance.post("/api/sms/send", {
    mobile: mobile,
    event: event,
  });
};

// 绑定手机
export const changemobile = (token, mobile, captcha) => {
  return axiosInstance.post(
    "/api/user/changemobile",
    {
      mobile: mobile,
      captcha: captcha,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取收款绑定信息
export const getPayBind = (uid) => {
  return axiosInstance.post("/api/agent/getPayBind", {
    uid: uid,
  });
};

// 绑定收款信息
export const bindPay = (uid, account, pic, type) => {
  return axiosInstance.post("/api/agent/bindPay", {
    uid: uid,
    account: account,
    pic: pic,
    type: type,
  });
};

// 获取首页轮播图
export const getBanner = () => {
  return axiosInstance.post("/api/index/getBanner", {});
};

// 获取服务器分类列表
export const getServerCategoryList = () => {
  return axiosInstance.post("/api/index/getServerCategoryList", {});
};

// 根据id获取服务器列表
export const getServerList = (id) => {
  return axiosInstance.post("/api/index/getServerList", {
    id: id,
  });
};

// 获取产品列表
export const getProduct = () => {
  return axiosInstance.post("/api/index/getProduct", {});
};

// 根据用户UID查询可使用的优惠卷
export const getRedpackForUID = (uid) => {
  return axiosInstance.post("/api/redpack/getRedpackForUID", {
    uid: uid,
  });
};

// 取出Yono单个游戏价格
export const getSingleGamePrice = () => {
  return axiosInstance.post("/api/activity/getSingleGamePrice", {});
};

// 用户购买游戏
export const buyGame = (
  token,
  uid,
  gameList,
  areaName,
  gameLanguage,
  price,
  redpack,
  type
) => {
  return axiosInstance.post(
    "/api/index/buyGame",
    {
      uid: uid,
      gameList: gameList,
      areaName: areaName,
      gameLanguage: gameLanguage,
      price: price,
      redpack: redpack,
      type: type,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 根据用户UID查询用户游戏订单
export const getUserOrder = (token, uid) => {
  return axiosInstance.post(
    "/api/user/getUserOrder",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 取出Coke单个游戏价格
export const getCokeGamePrice = () => {
  return axiosInstance.post("/api/activity/getCokeGamePrice", {});
};

// 根据用户UID获取需求列表
export const getServiceList = (token, uid) => {
  return axiosInstance.post(
    "/api/user/getServiceList",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 根据关联项目编号查询需求开发数据
export const getServiceInfo = (
  token,
  uid,
  product_code,
  page,
  pageSize,
  urgency,
  startTime,
  endTime
) => {
  return axiosInstance.post(
    "/api/user/getServiceInfo",
    {
      uid: uid,
      product_code: product_code,
      page: page,
      pageSize: pageSize,
      urgency: urgency,
      startTime: startTime,
      endTime: endTime,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取员工列表
export const getEmployeeList = (token) => {
  return axiosInstance.post(
    "/api/user/getEmployeeList",
    {},
    {
      headers: {
        token: token,
      },
    }
  );
};

// 创建需求工单
export const createNeedly = (
  token,
  uid,
  needly,
  file,
  urgency,
  product_code,
  employeeCode
) => {
  return axiosInstance.post(
    "/api/user/createNeedly",
    {
      uid: uid,
      needly: needly,
      file: file,
      urgency: urgency,
      product_code: product_code,
      employeeCode: employeeCode,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 需求开发撤单
export const cancelNeedly = (token, orderno, cancelReason) => {
  return axiosInstance.post(
    "/api/user/cancelNeedly",
    {
      orderno: orderno,
      cancelReason: cancelReason,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取员工列表
export const getService = (token, uid) => {
  return axiosInstance.post(
    "/api/user/getService",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取员工列表
export const getProducts = (token, uid) => {
  return axiosInstance.post(
    "/api/user/getProduct",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 通过项目编号获取需求开发与Bug缺陷数据
export const getProductInfo = (token, uid, product_code) => {
  return axiosInstance.post(
    "/api/user/getProductInfo",
    {
      uid: uid,
      product_code: product_code,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 根据关联项目编号获取Bug缺陷数据
export const getBugsInfo = (
  token,
  uid,
  product_code,
  page,
  pageSize,
  urgency,
  startTime,
  endTime
) => {
  return axiosInstance.post(
    "/api/user/getBugsInfo",
    {
      uid: uid,
      product_code: product_code,
      page: page,
      pageSize: pageSize,
      urgency: urgency,
      startTime: startTime,
      endTime: endTime,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// Bug缺陷撤单
export const cancelBugs = (token, orderno, cancelReason) => {
  return axiosInstance.post(
    "/api/user/cancelBugs",
    {
      orderno: orderno,
      cancelReason: cancelReason,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 创建Bug缺陷工单
export const createBugs = (
  token,
  uid,
  needly,
  file,
  urgency,
  probability,
  type,
  product_code,
  employeeCode
) => {
  return axiosInstance.post(
    "/api/user/createBugs",
    {
      uid: uid,
      needly: needly,
      file: file,
      urgency: urgency,
      probability: probability,
      type: type,
      product_code: product_code,
      employeeCode: employeeCode,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 通过订单号获取工单处理进度
export const getProcessProgress = (orderno, type) => {
  return axiosInstance.post("/api/work/getProcessProgress", {
    orderno: orderno,
    type: type,
  });
};

// 驳回工单
export const rejectOrder = (orderno, type, reason) => {
  return axiosInstance.post("/api/work/rejectOrder", {
    orderno: orderno,
    type: type,
    reason: reason,
  });
};

// 用户侧验收通过
export const passOrder = (orderno, type) => {
  return axiosInstance.post("/api/work/passOrder", {
    orderno: orderno,
    type: type,
  });
};

// 取出YonoGames技术报价
export const getYonoTecPrice = () => {
  return axiosInstance.post("/api/index/getYonoTecPrice", {});
};

// 根据用户UID查询用户服务器订单
export const getServerOrder = (token, uid) => {
  return axiosInstance.post(
    "/api/user/getServerOrder",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 根据用户UID查询用户服务器订单
export const getCustomOrder = (token, uid) => {
  return axiosInstance.post(
    "/api/user/getCustomOrder",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 服务续费
export const renewalService = (
  token,
  uid,
  renewalProductCode,
  month,
  amount,
  discount
) => {
  return axiosInstance.post(
    "/api/user/renewalService",
    {
      uid: uid,
      renewalProductCode: renewalProductCode,
      month: month,
      amount: amount,
      discount: discount,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 服务器续费
export const renewalServer = (token, uid, orderno, amount, month) => {
  return axiosInstance.post(
    "/api/user/renewalServer",
    {
      uid: uid,
      orderno: orderno,
      amount: amount,
      month: month,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 定制服务续费
export const renewalCustom = (token, uid, orderno, amount, month) => {
  return axiosInstance.post(
    "/api/user/renewalCustom",
    {
      uid: uid,
      orderno: orderno,
      amount: amount,
      month: month,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 定制服务续费
export const checkUserAgent = (token, uid) => {
  return axiosInstance.post(
    "/api/user/checkUserAgent",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 代理重置密码
export const checkServerForUID = (token, uid) => {
  return axiosInstance.post(
    "/api/user/checkServerForUID",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 根据关联项目编号查询需求开发数据
export const getServerListForArea = (token, uid, page, pageSize, area) => {
  return axiosInstance.post(
    "/api/user/getServerListForArea",
    {
      uid: uid,
      page: page,
      pageSize: pageSize,
      area: area,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取接口游戏
export const getInterfaceGame = (token) => {
  return axiosInstance.post(
    "/api/index/getInterfaceGame",
    {},
    {
      headers: {
        token: token,
      },
    }
  );
};

// 首页合并请求
export const indexRequest = (token) => {
  return axiosInstance.post(
    "/api/index/indexRequest",
    {},
    {
      headers: {
        token: token,
      },
    }
  );
};

// 用户购买Coke游戏
export const buyCokeGame = (
  token,
  uid,
  areaName,
  gameLanguage,
  price,
  redpack,
  type
) => {
  return axiosInstance.post(
    "/api/index/buyCokeGame",
    {
      uid: uid,
      areaName: areaName,
      gameLanguage: gameLanguage,
      price: price,
      redpack: redpack,
      type: type,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取所有已购买游戏列表
export const getUserGameList = (token, uid) => {
  return axiosInstance.post(
    "/api/index/getUserGameList",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取所有已购买游戏列表
export const getAgentData = (token, uid) => {
  return axiosInstance.post(
    "/api/agent/getAgentData",
    {
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取Api接口文档
export const getApiDoc = (token) => {
  return axiosInstance.post(
    "/api/apidoc/getApiDoc",
    {},
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取Api接口文档
export const checkToken = (token) => {
  return axiosInstance.post(
    "/api/user/fastLogin",
    {},
    {
      headers: {
        token: token,
      },
    }
  );
};

// 获取Api接口文档
export const renewalCloudServer = (token, orderno, uid) => {
  return axiosInstance.post(
    "/api/user/renewalCloudServer",
    {
      orderno: orderno,
      uid: uid,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};

// PayPal下单接口
export const createPaypalPaymentUrl = (token, uid, amount, cny) => {
  return axiosInstance.post(
    "/api/paypal/createPaypalPaymentUrl",
    {
      uid: uid,
      amount: amount,
      cny: cny,
    },
    {
      headers: {
        token: token,
      },
    }
  );
};
