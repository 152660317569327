<template>
  <div ref="chart" :style="{ width: width, height: height }"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: "600px",
    },
    height: {
      type: String,
      default: "400px",
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  watch: {
    options: {
      handler(newOptions) {
        if (this.myChart) {
          this.myChart.setOption(newOptions);
        }
      },
      deep: true,
    },
  },
  mounted() {
    // 初始化 ECharts
    this.myChart = echarts.init(this.$refs.chart);
    this.myChart.setOption(this.options);

    // 监听窗口大小变化，触发 resize
    window.addEventListener("resize", this.resizeChart);
  },
  beforeDestroy() {
    // 清理 ECharts 实例
    if (this.myChart) {
      this.myChart.dispose();
      this.myChart = null;
    }
    window.removeEventListener("resize", this.resizeChart);
  },
  methods: {
    resizeChart() {
      if (this.myChart) {
        this.myChart.resize();
      }
    },
  },
};
</script>
