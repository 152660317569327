<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item>
        <span>我的位置：</span>
        <span @click="chooseMenu(1)" style="cursor: pointer" class="clickable">
          个人中心</span
        >
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span @click="chooseMenu(6)" style="cursor: pointer" class="clickable"
          >我的服务</span
        >
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span @click="chooseMenu(6)" style="cursor: pointer" class="clickable"
          >外包维护包月服务</span
        >
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span @click="chooseMenu(101)" style="cursor: pointer" class="clickable"
          >项目编号：{{ productCode }}</span
        >
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span @click="chooseMenu(102)" style="cursor: pointer" class="clickable"
          >Bug缺陷</span
        >
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div style="display: flex; margin-top: 20px">
      <el-button
        type="primary"
        @click="(createNeedlyDialog = true), getEmployeeLists()"
        >+创建Bug缺陷</el-button
      >
      <el-select
        v-model="Urgency"
        style="margin-left: 10px"
        clearable
        placeholder="请选择紧急程度"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-date-picker
        v-model="time"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        style="margin-left: 10px"
      >
      </el-date-picker>
      <el-button
        type="primary"
        style="margin-left: 10px"
        @click="getBugsInfos()"
        >搜索</el-button
      >
    </div>
    <el-table
      :data="BugsData"
      v-loading="bugsloading"
      stripe
      style="width: 100%"
    >
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="orderno" label="Bug缺陷单号"> </el-table-column>
      <el-table-column prop="description" label="简要描述">
        <template slot-scope="scope">
          <span
            style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100px;
            "
            >{{ scope.row.description }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="urgency" label="紧急程度">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.urgency === 0">普通</el-tag>
          <el-tag v-else-if="scope.row.urgency === 1" type="warning"
            >一般</el-tag
          >
          <el-tag v-else-if="scope.row.urgency === 2" type="danger"
            >紧急</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" type="warning">待处理</el-tag>
          <el-tag v-else-if="scope.row.status === 1">处理中</el-tag>
          <el-tag v-else-if="scope.row.status === 2" type="success"
            >已完成</el-tag
          >
          <el-tag v-else-if="scope.row.status === 3" type="info">已撤单</el-tag>
          <el-tag v-else-if="scope.row.status === 4" type="success"
            >验收通过</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="end_time" label="完成时间">
        <template slot-scope="scope">
          <span v-if="scope.row.end_time">{{
            formatDate(scope.row.end_time)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="employee" label="当前处理人"> </el-table-column>
      <el-table-column label="操作" width="270">
        <template slot-scope="scope">
          <el-button type="primary" @click="openDialog(scope.row)"
            >查看</el-button
          >
          <el-button v-if="scope.row.status === 3" type="warning" disabled
            >催单</el-button
          >
          <el-button v-else type="warning" @click="open()">催单</el-button>
          <el-button v-if="scope.row.status === 3" type="info" disabled
            >撤单</el-button
          >
          <el-button
            v-else
            type="info"
            @click="openCancelDialog(scope.row.orderno)"
            >撤单</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 10px; float: right; margin-bottom: 20px"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :current-page="page"
      @current-change="handlePageChange"
    ></el-pagination>
    <!-- 创建Bug缺陷工单弹窗 -->
    <el-dialog
      title="创建Bug缺陷工单"
      :visible.sync="createNeedlyDialog"
      width="80%"
    >
      <el-row :gutter="20">
        <el-col :xs="24" :lg="15" :offset="0">
          <el-form>
            <el-form-item label="模板：">
              <el-select
                v-model="form.templateId"
                clearable
                placeholder="请选择模板"
              >
                <el-option
                  v-for="item in templateOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-button
                type="primary"
                style="margin-left: 10px"
                @click="chooseBugReportTemplate()"
                >从模板创建</el-button
              >
            </el-form-item>
            <el-form-item label="Bug缺陷：">
              <br />
              <quill-editor
                v-model="form.needly"
                ref="editor"
                :options="editorOptions"
                @blur="onBlur"
                @focus="onFocus"
                @change="onChange"
                style="height: 300px"
              ></quill-editor>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :lg="9" :offset="0">
          <el-row :gutter="10">
            <el-col :xs="24" :lg="12" :offset="0">
              <el-form>
                <el-form-item label="紧急程度：">
                  <br />
                  <div style="display: flex">
                    <el-select
                      v-model="form.urgency"
                      clearable
                      placeholder="请选择紧急程度"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <el-popover placement="bottom-start" trigger="hover">
                      <p>
                        请<span style="color: red">根据项目需求</span
                        >，结合自身紧急程度，合理提需求单。
                      </p>
                      <p>1.紧急需求单通常要求在24小时内处理完；</p>
                      <p>2.一般需求单通常要求在48小时内处理完；</p>
                      <p>3.普通需求单通常要求在72小时内处理完；</p>
                      <i
                        class="el-icon-question"
                        slot="reference"
                        style="font-size: 20px; margin-left: 10px"
                      ></i>
                    </el-popover>
                  </div>
                </el-form-item>
                <el-form-item label="指派给技术：">
                  <br />
                  <el-select
                    v-model="form.employee"
                    clearable
                    placeholder="请选择指派技术"
                  >
                    <el-option
                      v-for="item in employeeList"
                      :key="item.fake_work_no"
                      :label="item.fake_work_no"
                      :value="item.fake_work_no"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="附件上传：">
                  <!-- <el-upload
                  class="avatar-uploader"
                  :file-list="fileList"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleUploadSuccess"
                  multiple
                  list-type="picture-card"
                >
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload> -->
                  <el-upload
                    class="upload-demo"
                    action="https://api.jisuqipai.online/api/user/uploadFile"
                    :before-upload="beforeUpload"
                    :on-success="handleSuccess"
                    :file-list="customFileList"
                    :on-remove="handleRemove"
                    :data="extraParams"
                    :headers="headers"
                    accept=".zip,.png,.jpg,.jpeg,.gif"
                    multiple
                    :limit="3"
                    :on-exceed="handleExceed"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      v-loading.fullscreen.lock="fullscreenLoading"
                      >点击上传</el-button
                    >
                    <div slot="tip">
                      <el-popover
                        placement="bottom-start"
                        trigger="hover"
                        content="只能上传zip/rar/7z/png/gif/bmp/jpg/jpeg/webp/doc/ppt/xls/docx/pptx/xlsx/txt/rtf/pdf
                    文件，且不超过 10MB"
                      >
                        <el-button type="text" slot="reference"
                          >允许上传的文件类型</el-button
                        >
                      </el-popover>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-form></el-col
            >
            <el-col :xs="24" :lg="12" :offset="0">
              <el-form>
                <el-form-item label="出现频率：">
                  <br />
                  <el-select
                    v-model="form.probability"
                    clearable
                    placeholder="请选择出现频率"
                  >
                    <el-option
                      v-for="item in options1"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Bug缺陷分类：">
                  <br />
                  <el-select
                    v-model="form.type"
                    clearable
                    placeholder="请选择Bug缺陷分类"
                  >
                    <el-option
                      v-for="item in options2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item> </el-form
            ></el-col>
          </el-row>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="createNeedlyDialog = false">取 消</el-button>
        <el-button type="primary" @click="createBug()">提 交</el-button>
      </span>
    </el-dialog>
    <!-- 查看详情弹窗 -->
    <el-dialog title="查看详情" :visible.sync="checkInfoDialog" width="60%">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-descriptions title="需求信息" column="1">
            <el-descriptions-item label="需求单号">
              {{ Info.orderno }}
            </el-descriptions-item>
            <el-descriptions-item label="简要描述">
              <span v-html="Info.description"></span>
            </el-descriptions-item>
            <el-descriptions-item label="紧急程度">
              <el-tag v-if="Info.urgency === 0">普通</el-tag>
              <el-tag v-else-if="Info.urgency === 1" type="warning"
                >一般</el-tag
              >
              <el-tag v-else-if="Info.urgency === 2" type="danger">紧急</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="需求状态">
              <el-tag v-if="Info.status === 0" type="warning">待处理</el-tag>
              <el-tag v-else-if="Info.status === 1">处理中</el-tag>
              <el-tag v-else-if="Info.status === 2" type="success"
                >已完成</el-tag
              >
              <el-tag v-else-if="Info.status === 3" type="info">已撤单</el-tag>
              <el-tag v-else-if="Info.status === 4" type="success"
                >验收通过</el-tag
              >
            </el-descriptions-item>
            <el-descriptions-item label="创建时间">{{
              formatDate(Info.create_time)
            }}</el-descriptions-item>
            <el-descriptions-item label="完成时间">
              <span v-if="Info.end_time">{{ formatDate(Info.end_time) }}</span>
              <span v-else>尚未完成</span>
            </el-descriptions-item>
            <el-descriptions-item label="处理技术人员">
              <span>{{ Info.employee }}</span>
            </el-descriptions-item>
            <el-descriptions-item label="附件">
              <div v-for="(item, index) in Info.file" :key="index">
                <el-button
                  style="padding: 0; margin-right: 5px"
                  type="text"
                  @click="downloadFile(item.url)"
                  >{{ item.name || "未知文件" }}</el-button
                >
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </el-col>
        <el-col :span="12" :offset="0">
          <el-timeline v-loading="processLoading">
            <el-timeline-item
              :timestamp="formatDate(Info.create_time)"
              placement="top"
            >
              {{
                username +
                "提交于" +
                formatDate(Info.create_time) +
                "由技术人员" +
                Info.employee +
                "进行处理"
              }}
            </el-timeline-item>
            <el-timeline-item
              v-if="Info.status === 3"
              timestamp="工单关闭"
              placement="top"
            >
              {{ username + "操作工单撤销于" + formatDate(Info.create_time) }}
            </el-timeline-item>
            <el-timeline-item
              :timestamp="formatDate(item.create_time)"
              placement="top"
              v-for="item in processList"
              :key="item.id"
            >
              <span v-if="item.operate === 2">
                {{
                  item.employee +
                  "因" +
                  item.reason +
                  "将工单转交至" +
                  item.transfer
                }}
              </span>
              <span v-else-if="item.operate === 3">
                技术人员{{ item.employee }}已将工单处理完毕
              </span>
              <span v-else-if="item.operate === 4">
                {{ username }}已将工单驳回，工单恢复正在处理状态，驳回原因：{{
                  Info.rejectReason
                }}
              </span>
              <p style="margin: 0" v-if="item.operate === 4">
                {{ username }} 提交于
                {{ formatDate(item.create_time) }}
              </p>
              <p style="margin: 0" v-else>
                {{ item.employee }} 提交于
                {{ formatDate(item.create_time) }}
              </p>
              <div v-if="item.operate === 3" style="float: right">
                <el-button
                  v-if="
                    Info.status === 1 || item.operate === 4 || Info.status === 4
                  "
                  size="small"
                  disabled
                  type="success"
                  >验收通过</el-button
                >
                <el-button
                  size="small"
                  v-else
                  type="success"
                  @click="passOrders()"
                  >验收通过</el-button
                >
                <el-button
                  v-if="
                    Info.status === 1 || item.operate === 4 || Info.status === 4
                  "
                  size="small"
                  disabled
                  type="danger"
                  >驳回开发</el-button
                >
                <el-button
                  size="small"
                  v-else
                  type="danger"
                  @click="rejectDialog = true"
                  >驳回开发</el-button
                >
              </div>
            </el-timeline-item>
            <el-timeline-item
              v-if="Info.status === 4"
              :timestamp="formatDate(Info.end_time)"
              placement="top"
            >
              <span>
                {{ username }} 于
                {{ formatDate(Info.end_time) }}
                验收通过，工单关闭
              </span>
            </el-timeline-item>
          </el-timeline>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 撤单弹窗 -->
    <el-dialog title="撤单提示" :visible.sync="cancelDialog" width="30%">
      <el-alert
        title="当前需求已经提交，将会根据订单紧急程度进行处理"
        type="warning"
        :closable="false"
      >
      </el-alert>
      <el-input
        v-model="form.cancelReason"
        placeholder="请输入撤单原因"
        type="textarea"
        show-word-limit
        :rows="5"
        style="margin-top: 10px"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog = false">取 消</el-button>
        <el-button type="primary" @click="cancelBug()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 驳回弹窗 -->
    <el-dialog title="驳回提示" :visible.sync="rejectDialog" width="30%">
      <el-alert title="当前需求已完成" type="success" :closable="false">
      </el-alert>
      <el-input
        v-model="form.rejectReason"
        placeholder="请输入驳回原因"
        type="textarea"
        show-word-limit
        :rows="5"
        style="margin-top: 10px"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectDialog = false">取 消</el-button>
        <el-button type="primary" @click="rejectOrders()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  cancelBugs,
  createBugs,
  getBugsInfo,
  getEmployeeList,
  getProcessProgress,
  passOrder,
  rejectOrder,
  uploadImg,
} from "@/api/api";

import moment from "moment";

export default {
  props: {
    productCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      options: [
        {
          value: 0,
          label: "普通",
        },
        {
          value: 1,
          label: "一般",
        },
        {
          value: 2,
          label: "紧急",
        },
      ],
      options1: [
        {
          value: 1,
          label: "必然出现",
        },
        {
          value: 2,
          label: "高概率出现",
        },
        {
          value: 3,
          label: "低概率出现",
        },
      ],
      options2: [
        {
          value: 1,
          label: "逻辑性缺陷",
        },
        {
          value: 2,
          label: "界面显示缺陷",
        },
        {
          value: 3,
          label: "功能不完整缺陷",
        },
        {
          value: 4,
          label: "性能缺陷",
        },
        {
          value: 5,
          label: "异常闪退类缺陷",
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      editorOptions: {
        placeholder: "请输入内容...",
        theme: "snow", // 'bubble' 或 'snow'
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"], // 加粗、斜体、下划线
            ["image", "code-block"], // 插入图片或代码块
            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }], // 对齐方式
          ],
        },
      },
      templateOptions: [
        {
          value: 1,
          label: "简易BUG缺陷模板",
          content:
            "【缺陷类型】：<br/>【缺陷描述】：<br/>【复现步骤】：<br/>【截图】：",
        },
        {
          value: 2,
          label: "标准BUG缺陷模板",
          content:
            "【缺陷名称】：<br/>【缺陷类型】：<br/>【缺陷等级】：<br/>【缺陷描述】：<br/>【预期结果】：<br/>【实际结果】：<br/>【复现步骤】：<br/>【截图】：",
        },
      ],
      bugsloading: false,
      page: 1,
      pageSize: 10,
      Urgency: "",
      BugsData: [],
      time: "",
      total: "",
      createNeedlyDialog: false,
      form: [
        {
          needly: "",
        },
      ],
      imgList: [], // 保存上传成功的图片链接
      // fileList: [], // 显示已上传文件的列表
      progress: 0, // 上传进度
      img_loading: false, // 上传加载状态
      employeeList: [],
      checkInfoDialog: false,
      Info: [],
      fileList: [], // 当前文件列表
      uploadedFiles: [], // 上传成功后的文件路径列表
      headers: {
        token: localStorage.getItem("token"), // 设置请求头
      },
      customFileList: [],
      fullscreenLoading: false,
      allowedTypes: [
        "application/zip", // ZIP
        "application/x-rar-compressed", // RAR
        "application/x-7z-compressed", // 7Z
        "image/jpeg", // JPEG
        "image/png", // PNG
        "image/gif", // GIF
        "image/bmp", // BMP
        "image/webp", // WEBP
        "application/msword", // DOC
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
        "application/vnd.ms-powerpoint", // PPT
        "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
        "application/vnd.ms-excel", // XLS
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
        "text/plain", // TXT
        "application/rtf", // RTF
        "application/pdf", // PDF
      ],
      cancelOrderno: "",
      cancelDialog: false,
      username: "",
      processList: [],
      processLoading: false,
      rejectDialog: false,
    };
  },
  methods: {
    passOrders() {
      this.$confirm("确定要验收吗？", "验收提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: "正在操作",
            spinner: "el-icon-loading",
            background: "rgba(255, 255, 255, 0.7)",
          });
          passOrder(this.Info.orderno, 1).then((res) => {
            if (res.data.code === 200) {
              this.$message.success("验收成功");
              this.checkInfoDialog = false;
              this.getBugsInfos();
              loading.close();
            } else {
              this.$message.error("操作失败" + res.data.msg);
              loading.close();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消验收",
          });
        });
    },
    rejectOrders() {
      const loading = this.$loading({
        lock: true,
        text: "正在递交申请",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      rejectOrder(this.Info.orderno, 1, this.form.rejectReason).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("驳回成功！");
          this.checkInfoDialog = false;
          this.rejectDialog = false;
          this.getBugsInfos();
          loading.close();
        } else {
          this.$message.error("驳回失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    getProcessProgressList() {
      this.processLoading = true;
      getProcessProgress(this.Info.orderno, 1).then((res) => {
        if (res.data.code === 200) {
          this.processList = res.data.data;
          this.processLoading = false;
        } else {
          this.$message.error("获取进度失败！" + res.data.msg);
          this.processLoading = false;
        }
      });
    },
    cancelBug() {
      if ((this.cancelOrderno, this.form.cancelReason)) {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const token = localStorage.getItem("token");
        cancelBugs(token, this.cancelOrderno, this.form.cancelReason).then(
          (res) => {
            if (res.data.code === 200) {
              this.$message.success("撤单成功！");
              this.getBugsInfos();
              this.cancelDialog = false;
              loading.close();
            } else {
              this.$message.error("撤单失败！" + res.data.msg);
              this.cancelDialog = false;
              loading.close();
            }
          }
        );
      } else {
        this.$message.error("请填写撤单原因！");
      }
    },
    openCancelDialog(orderno) {
      this.cancelOrderno = orderno;
      this.cancelDialog = true;
    },
    chooseBugReportTemplate() {
      const selectedTemplate = this.templateOptions.find(
        (item) => item.value === this.form.templateId
      );
      if (selectedTemplate) {
        const id = this.form.templateId;
        this.form.needly = selectedTemplate.content;
        this.form.templateId = null;
        this.form.templateId = id;
      } else {
        this.form.needly = null;
        this.form.templateId = null;
      }
    },
    downloadFile(url) {
      window.open(url);
    },
    // 上传前检查文件类型和大小
    beforeUpload(file) {
      this.fullscreenLoading = true;
      const isAllowedType = this.allowedTypes.includes(file.type);
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isAllowedType) {
        this.$message.error("只能上传 ZIP 或图片文件");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("文件大小不能超过 10MB");
        return false;
      }
      return true;
    },

    // 上传成功回调
    handleSuccess(response, file) {
      if (response.code === 200) {
        this.$message.success("文件上传成功");
        this.fullscreenLoading = false;
        // 将文件信息插入到自定义列表中
        this.customFileList.push({
          name: file.name, // 原文件名
          url: response.data.relative_path, // 文件路径
        });
      } else {
        this.$message.error("上传失败: " + response.message);
        this.fullscreenLoading = false;
      }
    },

    // 文件移除回调
    handleRemove(file) {
      // 从 customFileList 中移除被删除的文件
      this.customFileList = this.customFileList.filter(
        (item) => item.url !== file.url
      );
    },

    // 文件超出限制
    handleExceed() {
      this.$message.warning("文件数量超出限制");
    },
    openDialog(row) {
      this.checkInfoDialog = true;
      this.Info = row;
      row.file = JSON.parse(this.Info.file);
      this.getProcessProgressList();
    },
    open() {
      this.$alert(
        "当前需求已经提交，请您耐心等待！确定要催单吗？",
        "催单提示",
        {
          confirmButtonText: "确定",
          callback: () => {
            const loading = this.$loading({
              lock: true,
              text: "Loading",
              spinner: "el-icon-loading",
              background: "rgba(255, 255, 255, 0.7)",
            });
            setTimeout(() => {
              loading.close();
              this.$message({
                type: "success",
                message: "催单成功！",
              });
            }, 1000);
          },
        }
      );
    },
    createBug() {
      if (
        (this.form.needly,
        this.customFileList,
        this.form.urgency,
        this.form.probability,
        this.form.type,
        this.productCode,
        this.form.employee)
      ) {
        const token = localStorage.getItem("token");
        const uid = localStorage.getItem("uid");
        createBugs(
          token,
          uid,
          this.form.needly,
          this.customFileList,
          this.form.urgency,
          this.form.probability,
          this.form.type,
          this.productCode,
          this.form.employee
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("创建需求成功！");
            this.createNeedlyDialog = false;
            this.getBugsInfos();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("请填写完整信息");
      }
    },
    getEmployeeLists() {
      const token = localStorage.getItem("token");
      getEmployeeList(token).then((res) => {
        if (res.data.code === 200) {
          this.employeeList = res.data.data;
        } else {
          this.$message.error("获取员工列表失败！" + res.data.msg);
        }
      });
    },
    beforeAvatarUpload(file) {
      // 限制上传数量
      if (this.fileList.length >= 5) {
        this.$message.warning("最多只能上传 5 张图片！");
        return false; // 阻止上传
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);

      // 异步读取文件为 Base64 格式
      reader.onload = () => {
        const base64Image = reader.result; // 获取 Base64 格式的图片

        // 创建上传进度的加载框
        const loading = this.$loading({
          lock: true,
          text: "正在上传中 " + this.progress + "%",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });

        // 调用上传图片的接口
        uploadImg(base64Image, {
          onUploadProgress: (progressEvent) => {
            this.progress = Math.floor(progressEvent.progress * 100);
            loading.text = "正在上传中 " + this.progress + "%";
          },
        })
          .then((response) => {
            if (response.data.code === 200) {
              const imageUrl = response.data.data; // 返回的图片链接
              this.imgList.push(imageUrl); // 保存到 imgList 数组中
              this.fileList.push({ url: imageUrl }); // 用于显示图片预览
              this.$message.success("图片上传成功！");
            } else {
              this.$message.error(response.data.msg || "上传失败");
            }
            loading.close(); // 关闭加载框
          })
          .catch((error) => {
            console.error("图片上传失败:", error);
            this.$message.error("上传失败，请稍后再试！");
            loading.close(); // 关闭加载框
          });
      };

      return false; // 阻止默认的上传行为
    },
    handleUploadSuccess(response, file, fileList) {
      // 如果有其他成功逻辑需要处理，可以在这里添加
      console.log("上传成功", response, file, fileList);
    },
    handlePageChange(page) {
      this.page = page;
      this.getBugsInfos(); // 请求新页面的数据
    },
    getBugsInfos() {
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      const [startTime, endTime] = this.time || ["", ""];
      // const startTime = "";
      // const endTime = "";
      this.bugsloading = true;
      getBugsInfo(
        token,
        uid,
        this.productCode,
        this.page,
        this.pageSize,
        this.Urgency,
        startTime,
        endTime
      ).then((res) => {
        if (res.data.code === 200) {
          this.BugsData = res.data.data.data;
          this.total = res.data.data.total;
          console.log(res.data.data);
          this.bugsloading = false;
        } else {
          this.$message.error("获取需求信息失败！" + res.data.msg);
          this.bugsloading = false;
        }
      });
    },
    chooseMenu(data) {
      this.$emit("custom-event", data);
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    onBlur() {
      console.log("编辑器失去焦点");
    },
    onFocus() {
      console.log("编辑器获得焦点");
    },
    onChange(content) {
      console.log("内容发生变化", content);
    },
  },
  created() {
    this.getBugsInfos();
    this.username = localStorage.getItem("username");
  },
};
</script>

<style></style>
