<template>
  <div>
    <div
      style="
        background-color: white;
        padding: 20px 150px;
        border-top: 1px #dcdfe6 solid;
        border-bottom: 1px #dcdfe6 solid;
      "
    >
      <h1 style="margin: 0">极速棋牌接口游戏文档</h1>
    </div>
    <el-row
      v-loading="loading"
      :gutter="20"
      style="width: 100%; border-bottom: 1px #dcdfe6 solid"
    >
      <el-col :span="5" :offset="0">
        <el-menu
          :default-active="1"
          text-color="#909399"
          active-text-color="#cb1414"
          style="height: 85vh; overflow: auto"
        >
          <div v-for="item in ApiData" :key="item.id">
            <el-menu-item
              v-if="item.children.length == 0"
              :index="item.id"
              @click="content = item.content"
            >
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
            <el-submenu v-else :index="item.id">
              <template slot="title">
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group v-for="item in item.children" :key="item.id">
                <el-menu-item
                  :index="item.id"
                  @click="content = item.content"
                  >{{ item.title }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </div>
        </el-menu>
      </el-col>
      <el-col :span="19" :offset="0">
        <div
          v-show="showMenu == '1'"
          v-html="content"
          style="height: 85vh; width: 100%; overflow: auto"
        ></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getApiDoc } from "@/api/api";
export default {
  data() {
    return {
      showMenu: "1",
      ApiData: [],
      loading: false,
      content: "",
    };
  },
  methods: {
    getApiDocs() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getApiDoc(token).then((res) => {
        if (res.data.code === 200) {
          this.ApiData = res.data.data;
          this.content = this.ApiData[0].content;
          this.loading = false;
          return;
        }
        this.$message.error("获取文档失败！" + res.data.msg);
        this.loading = false;
      });
    },
  },
  created() {
    this.getApiDocs();
  },
};
</script>

<style></style>
