<template>
  <div>
    <div class="create_banner">
      <h1 style="margin: 5px; font-size: 40px">CokeJogo深度二次开发</h1>
      <h1 style="margin: 5px; font-size: 25px">出海接口游戏&短视频爆款产品</h1>
    </div>
    <div style="text-align: center; background-color: white; overflow: hidden">
      <h1 style="margin: 0; padding: 20px">游戏区域</h1>
      <el-row :gutter="30" class="create_coke_main">
        <el-col
          :xs="24"
          :lg="8"
          :offset="0"
          style="display: flex; justify-content: center; margin-bottom: 20px"
          v-for="item in area"
          :key="item.id"
        >
          <div
            @click="chooseArea(item.name)"
            :style="{
              border:
                areaName === item.name
                  ? '1px #ff0000 solid'
                  : '1px #909399 solid',
              borderRadius: '20px',
              width: '300px',
              boxShadow:
                areaName === item.name ? '0 0 20px #ff0000' : '0 0 0px #909399',
              transition: 'all 0.3s ease',
            }"
          >
            <img
              :src="item.img"
              alt=""
              style="
                height: 180px;
                width: 100%;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
              "
            />
            <div
              style="
                display: flex;
                justify-content: space-between;
                padding: 0 30px 10px 30px;
              "
            >
              <p style="margin: 0; font-size: 20px; margin-top: 5px">
                {{ item.name }}
              </p>
              <img :src="item.flag" alt="" style="height: 40px" />
            </div>
          </div>
        </el-col>
      </el-row>
      <h1 style="margin: 0; padding: 20px; margin-top: 40px">游戏语言</h1>
      <el-row :gutter="30" class="create_coke_main">
        <el-col
          :xs="24"
          :lg="8"
          :offset="0"
          style="display: flex; justify-content: center"
          v-for="item in language"
          :key="item.id"
        >
          <div
            @click="chooseLanguage(item.name)"
            :style="{
              display: 'flex',
              border:
                gameLanguage === item.name
                  ? '1px #ff0000 solid'
                  : '1px #909399 solid',
              width: '300px',
              justifyContent: 'space-around',
              borderRadius: '10px',
              padding: '10px 0',
              boxShadow:
                gameLanguage === item.name
                  ? '0 0 20px #ff0000'
                  : '0 0 0px #909399',
              transition: 'all 0.3s ease',
            }"
          >
            <p style="font-size: 25px; line-height: 10px; color: #666666">
              {{ item.name }}
            </p>
            <img :src="item.img" alt="" style="width: 100px; height: 60px" />
          </div>
        </el-col>
      </el-row>
      <!-- <h1 style="margin: 0; padding: 20px; margin-top: 40px">部署方案</h1>
      <div style="display: flex; justify-content: center">
        <h1
          class="create_server"
          :class="{ selected: selectedOption === 1 }"
          @click="selectOption(1)"
        >
          从现有服务器
        </h1>
        <h1
          class="create_server"
          :class="{ selected: selectedOption === 2 }"
          @click="selectOption(2)"
          style="margin-left: 100px"
        >
          云服务部署方式
        </h1>
      </div>
      <div
        v-show="selectedOption === 1"
        style="
          text-align: left;
          margin: 0 180px;
          border: 0.5px #666666 solid;
          border-radius: 20px;
          padding: 50px;
        "
      >
        <p style="font-size: 30px; margin: 10px">SSH信息</p>
        <el-row :gutter="20" style="text-align: center">
          <el-col :span="12" :offset="0">
            <div>
              <span style="font-size: 20px">服务器IP：</span>
              <input
                type="text"
                style="
                  background-color: #dedede;
                  border: 0;
                  border-radius: 3px;
                  height: 30px;
                  transform: translateY(-3px);
                "
              />
            </div>
            <div style="margin-top: 30px">
              <span style="font-size: 20px">端口号：</span>
              <input
                type="text"
                style="
                  background-color: #dedede;
                  border: 0;
                  border-radius: 3px;
                  height: 30px;
                  transform: translateY(-3px);
                  margin-left: 15px;
                "
              />
            </div>
          </el-col>
          <el-col :span="12" :offset="0">
            <div>
              <span style="font-size: 20px">用户名：</span>
              <input
                type="text"
                style="
                  background-color: #dedede;
                  border: 0;
                  border-radius: 3px;
                  height: 30px;
                  transform: translateY(-3px);
                "
              />
            </div>
            <div style="margin-top: 30px">
              <span style="font-size: 20px">密码：</span>
              <input
                type="text"
                style="
                  background-color: #dedede;
                  border: 0;
                  border-radius: 3px;
                  height: 30px;
                  transform: translateY(-3px);
                  margin-left: 15px;
                "
              />
            </div>
          </el-col>
        </el-row>
      </div>
      <div
        v-show="selectedOption === 2"
        style="
          text-align: left;
          margin: 0 180px;
          border: 0.5px #666666 solid;
          border-radius: 20px;
          padding: 50px;
        "
      >
        <p style="font-size: 30px">选择购买服务时长</p>
        <el-row :gutter="20">
          <el-col :span="6" :offset="0" v-for="item in time" :key="item.id">
            <div
              @click="chooseServiceTime(item.time)"
              :style="{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#ffeae6',
                borderRadius: '10px',
                border:
                  serviceTime === item.time
                    ? '1px #ff0000 solid'
                    : '1px #666666 solid',
                boxShadow:
                  serviceTime === item.time
                    ? '0 0 20px #ff0000'
                    : '0 0 0px #909399',
                transition: 'all 0.3s ease',
              }"
            >
              <i
                class="el-icon-caret-right"
                style="color: #1f62b5; line-height: 85px; font-size: 20px"
              ></i>
              <p style="font-size: 25px; margin-left: 30px">{{ item.time }}</p>

              <div
                v-if="item.discount != '无折扣'"
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 0;
                  height: 0;
                  border-top: 50px solid red;
                  border-left: 50px solid transparent;
                  border-radius: 0 10px 0 0;
                "
              >
                <span
                  style="
                    position: absolute;
                    top: -30px;
                    right: -30px;
                    left: -30px;
                    transform: rotate(45deg);
                    color: white;
                    font-size: 14px;
                  "
                  >{{ item.discount }}</span
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div> -->
      <div class="create_coke_main" style="margin-top: 20px">
        <div style="display: flex">
          <p style="margin-left: auto">整套游戏售价：</p>
          <p style="width: 250px; margin: 10px 0; text-align: right">
            ￥<span style="color: red">{{ gamePrice }}</span>
          </p>
        </div>
        <div style="display: flex">
          <p style="margin-left: auto">可使用优惠券：</p>
          <el-select
            v-model="redPack"
            placeholder="请选择要使用的优惠券"
            v-loading="redPackLoading"
            style="width: 250px"
          >
            <el-option
              v-for="item in filteredRedPackList"
              :key="item.id"
              :label="item.pack_amount + '[满' + item.min_amount + '可使用]'"
              :value="item.pack_amount"
            ></el-option>
          </el-select>
        </div>
        <div style="display: flex">
          <p style="margin-left: auto">实际支付价格：</p>
          <p
            v-if="redPack === ''"
            style="width: 250px; margin: 10px 0; text-align: right"
          >
            ￥<span style="color: red">{{ gamePrice }}</span>
          </p>
          <p v-else style="width: 250px; margin: 10px 0; text-align: right">
            ￥<span style="color: red">{{ gamePrice }}</span> - ￥<span
              style="color: red"
              >{{ redPack }}</span
            >
            = ￥<span style="color: red">{{ gamePrice - redPack }}</span>
          </p>
        </div>
        <div style="display: flex">
          <button
            style="
              font-size: 20px;
              background-color: #1f62b5;
              color: white;
              border: 0;
              padding: 10px 30px;
              border-radius: 10px;
              margin: 20px 0;
              cursor: pointer;
              margin-left: auto;
            "
            @click="buyCokeGames()"
          >
            立即购买
          </button>
        </div>
      </div>
      <!-- 价格计算 -->
      <!-- <div
        style="
          text-align: left;
          margin: 60px 180px 0 180px;
          padding-bottom: 100px;
        "
      >
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <p style="font-size: 30px">价格计算：</p>
          </el-col>
          <el-col :span="12" :offset="0" style="text-align: right">
            <el-select
              v-model="value"
              placeholder="请选择优惠卷"
              style="margin-top: 30px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <p style="font-size: 20px; color: orangered; font-weight: bolder">
              总计价格：30,000CNY
            </p>
            <p style="font-size: 20px; color: orangered; font-weight: bolder">
              优惠金额：1,000CNY
            </p>
            <p style="font-size: 20px; color: orangered; font-weight: bolder">
              结算价格：29,000CNY
            </p>
            <button
              style="
                font-size: 20px;
                background-color: #1f62b5;
                color: white;
                border: 0;
                padding: 10px 30px;
                border-radius: 10px;
              "
            >
              立即支付
            </button>
          </el-col>
        </el-row>
      </div> -->
    </div>
    <!-- 购买成功弹窗 -->
    <el-dialog :visible.sync="buySuccessDialog" width="30%">
      <div style="text-align: center">
        <img
          src="@/assets/success.png"
          alt="success"
          style="width: 100px; height: 100px"
        />
        <h1>购买成功</h1>
        <p>已经扣除您的账户余额和红包</p>
      </div>
      <el-alert
        type="success"
        description="已经成功生成订单，我们稍后将为您配置资源，如有疑问请联系商务进行沟通处理"
        show-icon
        :closable="false"
      >
      </el-alert>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="buySuccessDialog = false">确 定</el-button>
        <el-button type="primary" @click="$router.push('/user')"
          >前往个人中心</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { buyCokeGame, getCokeGamePrice, getRedpackForUID } from "@/api/api";

export default {
  data() {
    return {
      selectedOption: 1,
      serverIp: "",
      serverPort: "",
      username: "",
      password: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      area: [
        {
          id: 1,
          img: require("@/assets/created/india.png"),
          name: "印度",
          flag: require("@/assets/created/Flag_of_India.png"),
        },
        {
          id: 2,
          img: require("@/assets/created/brazil.png"),
          name: "巴西",
          flag: require("@/assets/created/Flag_of_Brazil.png"),
        },
        {
          id: 3,
          img: require("@/assets/created/Indonesia.png"),
          name: "印尼",
          flag: require("@/assets/created/Flag_of_Indonesia.png"),
        },
        {
          id: 4,
          img: require("@/assets/created/Malaysia.jpg"),
          name: "马来西亚",
          flag: require("@/assets/created/Flag_of_Malaysia.svg.png"),
        },
        {
          id: 5,
          img: require("@/assets/created/Philippines.jpg"),
          name: "菲律宾",
          flag: require("@/assets/created/Flag_of_the_Philippines.svg.png"),
        },
        {
          id: 6,
          img: require("@/assets/created/newyork.jpg"),
          name: "美国",
          flag: require("@/assets/created/Flag_of_the_United_States.svg.png"),
        },
      ],
      areaName: "",
      gameLanguage: "",
      language: [
        {
          id: 1,
          img: require("@/assets/created/lang_english.png"),
          name: "英语",
        },
        {
          id: 2,
          img: require("@/assets/created/lang_portuguese.png"),
          name: "葡萄牙语",
        },
        {
          id: 3,
          img: require("@/assets/created/lang_india.png"),
          name: "印地语",
        },
      ],
      time: [
        {
          id: 1,
          time: "1个月",
          discount: "无折扣",
        },
        {
          id: 2,
          time: "3个月",
          discount: "9折",
        },
        {
          id: 3,
          time: "半年",
          discount: "8折",
        },
        {
          id: 4,
          time: "1年",
          discount: "6折",
        },
      ],
      serviceTime: "",
      redPackList: [],
      redPack: "",
      redPackLoading: false,
      gamePrice: 0,
      buySuccessDialog: false,
    };
  },
  methods: {
    buyCokeGames() {
      const loading = this.$loading({
        lock: true,
        text: "正在进行购买扣款，请稍等",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      const type = "CokeJogo";
      buyCokeGame(
        token,
        uid,
        this.areaName,
        this.gameLanguage,
        this.gamePrice,
        this.redPack,
        type
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("购买成功！");
          this.buySuccessDialog = true;
          loading.close();
          return;
        }
        this.$message.error("购买失败！" + res.data.msg);
        loading.close();
      });
    },
    getCokeGamePrices() {
      getCokeGamePrice().then((res) => {
        if (res.data.code === 200) {
          this.gamePrice = res.data.data;
        } else {
          this.$message.error("获取单个游戏价格失败！");
        }
      });
    },
    getRedpackForUIDs() {
      this.redPackLoading = true;
      const uid = localStorage.getItem("uid");
      getRedpackForUID(uid).then((res) => {
        if (res.data.code === 200) {
          this.redPackList = res.data.data;
          this.redPackLoading = false;
        } else {
          this.$message.error("获取优惠卷失败！" + res.data.msg);
          this.redPackLoading = false;
        }
      });
    },
    chooseServiceTime(time) {
      this.serviceTime = time;
    },
    chooseLanguage(lang) {
      this.gameLanguage = lang;
    },
    chooseArea(area) {
      this.areaName = area;
    },
    selectOption(option) {
      this.selectedOption = option; // 切换选中的元素
    },
  },
  created() {
    this.getRedpackForUIDs();
    this.getCokeGamePrices();
    const token = localStorage.getItem("token");
    if (!token) {
      this.$message.warning("请先登录");
      this.$router.push("/login");
    }
  },
  computed: {
    filteredRedPackList() {
      // 筛选满足条件的优惠券
      return this.redPackList.filter(
        (item) => parseFloat(item.min_amount) <= this.gamePrice
      );
    },
  },
};
</script>

<style>
.create_coke_main {
  padding: 0 150px;
}

.create_banner {
  background-image: url("@/assets/created/banner.png");
  background-size: cover;
  height: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.create_banner h1 {
  color: white;
  text-shadow: -2px -2px 0 #304978, /* 左上 */ 2px -2px 0 #304978,
    /* 右上 */ -2px 2px 0 #304978, /* 左下 */ 2px 2px 0 #304978; /* 右下 */
}

.create_server {
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 20px;
  transition: all 0.2s ease;
}

.selected {
  background-color: #a8d0e6 !important; /* 选中时的背景颜色 */
}

@media screen and (max-width: 768px) {
  .create_coke_main {
    padding: 0 20px;
  }
}
</style>
