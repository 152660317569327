import Vue from "vue";
import axios from "axios";
import ElementUI from "element-ui";
import TDesign from "tdesign-vue";
import "element-ui/lib/theme-chalk/index.css";
import "tdesign-vue/es/style/index.css";
import "font-awesome/css/font-awesome.min.css";

import router from "./router/router.js";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import App from "./App.vue";

Vue.use(TDesign);
Vue.use(ElementUI);
Vue.use(VueQuillEditor);

// 配置axios全局使用
Vue.prototype.$http = axios;

new Vue({
  router,
  el: "#app",
  render: (h) => h(App),
});
