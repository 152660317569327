<template>
  <div>
    <div class="bg">
      <el-row>
        <el-col :xs="0" :lg="12">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 600px;
            "
          >
            <img
              src="@/assets/abg.png"
              alt=""
              style="width: 100%; height: 100%"
            />
          </div>
        </el-col>
        <el-col :xs="24" :lg="12">
          <div
            class="agentLoginArea"
            style="
              height: 600px;
              backdrop-filter: blur(10px);
              background-color: rgb(255, 255, 255);
              padding: 130px;
            "
          >
            <div style="display: flex">
              <h1>极速代理登录</h1>
              <h3
                v-if="showQuickLogin === true"
                style="margin-left: auto; color: #cb1414; cursor: pointer"
                @click="(showQuickLogin = false), (quick = 0)"
              >
                切换账号密码登录
              </h3>
            </div>
            <p
              v-if="showQuickLogin === true"
              style="margin: 10px 0; color: #666666; text-align: center"
            >
              点击头像使用当前账户快速登录极速棋牌代理后台
            </p>
            <div
              v-if="showQuickLogin === true"
              style="display: flex; justify-content: center"
            >
              <el-card style="width: 300px">
                <div style="display: flex">
                  <el-avatar
                    :size="60"
                    style="cursor: pointer"
                    @click.native="quickLogin()"
                  >
                    <img :src="agentData.avatar" />
                  </el-avatar>
                  <!-- <div>
                    <p>
                      {{ agentData.username }}
                    </p>
                  </div> -->
                  <div>
                    <p style="margin: 5px 10px">
                      {{ agentData.username }}
                    </p>
                    <p style="margin: 0 10px">
                      <span v-if="agentData.level === '1'"
                        >欢迎您！银牌代理
                      </span>
                      <span v-if="agentData.level === '2'">
                        欢迎您！尊敬的金牌代理
                      </span>
                      <span v-if="agentData.level === '3'">
                        欢迎您！尊贵的钻石代理
                      </span>
                    </p>
                  </div>
                </div>
              </el-card>
            </div>
            <div v-if="showQuickLogin === false">
              <input
                type="text"
                placeholder="请输入账号"
                v-model="account"
                style="
                  background-color: rgba(255, 255, 255, 0.3);
                  border: 0px;
                  border-bottom: 0.5px #666666 solid;
                  height: 30px;
                  width: 100%;
                  font-size: 17px;
                  font-weight: bold;
                  margin-top: 20px;
                "
              />
              <input
                type="password"
                placeholder="请输入密码"
                v-model="password"
                style="
                  background-color: rgba(255, 255, 255, 0.3);
                  border: 0px;
                  border-bottom: 0.5px #666666 solid;
                  height: 30px;
                  width: 100%;
                  font-size: 17px;
                  font-weight: bold;
                  margin-top: 80px;
                "
              />
              <div
                style="
                  background-color: #cb1414;
                  font-size: 30px;
                  color: white;
                  margin-top: 50px;
                  text-align: center;
                  padding: 10px;
                  width: 100%;
                "
                @click="Logins()"
              >
                登录
              </div>
            </div>
            <el-row>
              <el-col :xs="24" :lg="12" :offset="0">
                <p
                  v-if="isLogin === 'true'"
                  style="font-weight: bolder"
                  @click="AgentDialog = true"
                >
                  创建代理用户
                </p>
                &nbsp;
              </el-col>
              <el-col :xs="24" :lg="12" :offset="0">
                <p
                  @click="findPwDialogVisible = true"
                  style="color: #cb1414; font-weight: bold; float: right"
                  class="findPw"
                >
                  找回密码
                </p>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 找回密码 -->
    <el-dialog
      title="找回密码"
      :visible.sync="findPwDialogVisible"
      :width="dialogWidth"
      center
      style="border: 1px #cb1414 solid"
    >
      <input
        type="text"
        placeholder="请输入邮箱账号"
        v-model="email"
        class="resetInput"
        style="margin-top: 20px"
      />
      <div style="display: flex; align-items: flex-end">
        <input
          type="text"
          placeholder="请输入验证码"
          v-model="resetCaptcha"
          class="resetInput"
          style="margin-top: 40px"
        />
        <button
          @click="resetPwdMail()"
          style="
            background-color: #cb1414;
            color: white;
            border: 0px;
            font-size: 15px;
            width: 160px;
            padding: 10px 10px;
          "
        >
          {{ countdown > 0 ? `重新发送(${countdown}s)` : "获取验证码" }}
        </button>
      </div>
      <input
        type="password"
        placeholder="请输入您的新密码"
        v-model="newPassword"
        class="resetInput"
        style="margin-top: 40px"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="findPwDialogVisible = false">取 消</el-button>
        <el-button
          style="background-color: #cb1414; color: white"
          @click="resetpwds()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 成为代理用户 -->
    <el-dialog
      title="代理用户创建提示"
      :visible.sync="AgentDialog"
      :width="dialogWidth"
      center
    >
      <p>当前用户为{{ username }}</p>
      <p>即将使用该账号创建代理用户，您确定创建代理用户吗？</p>
      <span slot="footer" class="dialog-footer">
        <button @click="AgentDialog = false" style="background-color: #666666">
          取 消
        </button>
        <button type="primary" @click="createAgents()">确 定</button>
      </span>
    </el-dialog>
    <!-- 代理用户密码提醒 -->
    <el-dialog
      title="代理用户创建提示"
      :visible.sync="CreateDialog"
      :width="dialogWidth"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <p>
        当前创建的代理用户为<span
          style="font-weight: bolder; font-style: italic"
          >{{ username }}</span
        >
      </p>
      <p>
        当前创建的用户密码为
        <span style="font-weight: bolder; font-style: italic">
          {{ randomPw }}
        </span>
      </p>
      <p style="color: red; font-weight: bolder; font-style: italic">
        此弹窗关闭之后将不再留存您的用户密码，请谨慎保管
      </p>
      <span slot="footer" class="dialog-footer">
        <span v-if="countdown != 0">{{ countdown }}秒后可关闭弹窗</span>
        <button
          v-if="countdown === 0"
          type="primary"
          @click="CreateDialog = false"
        >
          关闭弹窗
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMailCaptcha,
  AgentLogin,
  resetpwd,
  createAgent,
  checkToken,
} from "@/api/api";
// import moment from "moment";

export default {
  data() {
    return {
      account: "",
      password: "",
      captcha: "",
      findPwDialogVisible: false,
      email: "",
      newPassword: "",
      resetCaptcha: "",
      countdown: 0, // 倒计时秒数
      timer: null, // 保存定时器
      isLogin: false,
      username: "",
      randomPw: "",
      AgentDialog: false,
      CreateDialog: false,
      dialogWidth: "30%",
      btnFalse: false,
      showQuickLogin: false,
      agentData: [],
      quick: 0,
    };
  },
  methods: {
    checkTokens() {
      const token = localStorage.getItem("token");
      checkToken(token).then((res) => {
        if (res.data.data === 1) {
          // this.$message.success("快速登录");
          this.showQuickLogin = true;
          this.quick = 1;
          this.agentData.username = localStorage.getItem("username");
          this.agentData.avatar = localStorage.getItem("avatar");
          this.agentData.level = localStorage.getItem("level");
          return;
        }
        // this.$message.error("请先登录");
      });
    },
    quickLogin() {
      this.account = this.agentData.username;
      this.Logins();
    },
    // quickAgentLogin() {
    //   const currentTime = moment().unix();
    //   const loginTime = localStorage.getItem("loginTime");
    //   if (currentTime - loginTime < 300) {
    //     this.showQuickLogin = true;
    //     this.quick = 1;
    //     this.agentData.username = localStorage.getItem("username");
    //     this.agentData.avatar = localStorage.getItem("avatar");
    //     this.agentData.level = localStorage.getItem("level");
    //   } else {
    //     this.showQuickLogin = false;
    //   }
    // },
    createAgents() {
      const loading = this.$loading({
        lock: true,
        text: "正在全力加载中",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      createAgent(token, this.username).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("创建代理用户成功！");
          this.randomPw = res.data.data;
          this.AgentDialog = false;
          this.CreateDialog = true;
          // 启动倒计时，5秒倒计时
          this.countdown = 5;
          this.btnFalse = true;
          this.timer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              clearInterval(this.timer); // 倒计时结束时清除定时器
              this.btnFalse = false;
            }
          }, 1000);
          loading.close();
        } else {
          this.$message.error("创建代理用户失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    Logins() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      AgentLogin(this.account, this.password, this.quick).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("登录成功！即将前往极速棋牌代理后台");
          localStorage.setItem("token", res.data.data.userinfo.token);
          localStorage.setItem("avatar", res.data.data.userinfo.avatar);
          localStorage.setItem("nickname", res.data.data.userinfo.nickname);
          localStorage.setItem("username", res.data.data.userinfo.username);
          localStorage.setItem("mobile", res.data.data.userinfo.mobile);
          localStorage.setItem("uid", res.data.data.userinfo.aid);
          localStorage.setItem("level", res.data.data.userinfo.level);
          // localStorage.setItem("amount", res.data.data.userinfo.amount);
          localStorage.setItem("isAgentLogin", true);
          localStorage.setItem("Agent", true);
          setTimeout(() => {
            loading.close();
            this.$router.push("/agentHome");
            location.reload();
          }, 3000);
        } else {
          this.$message.error("登录失败!" + res.data.msg);
          loading.close();
        }
      });
    },
    resetPwdMail() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const event = "resetpwd";
      getMailCaptcha(this.email, event)
        .then((res) => {
          if (res.data.data === 200) {
            // 启动倒计时，60秒倒计时
            this.countdown = 60;
            this.timer = setInterval(() => {
              if (this.countdown > 0) {
                this.countdown--;
              } else {
                clearInterval(this.timer); // 倒计时结束时清除定时器
              }
            }, 1000);
            this.$message.success("验证码发送成功！");
            loading.close();
          } else {
            this.$message.error("验证码发送失败！" + res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          this.$message.error("验证码发送失败！" + res.data.msg);
        });
    },
    resetpwds() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      resetpwd(this.email, this.newPassword, this.resetCaptcha).then((res) => {
        if (res.data.code === 1) {
          loading.close();
          this.$message.success("重置密码成功！");
          this.findPwDialogVisible = false;
          // setTimeout(() => {
          //   this.$router.push("/login");
          // }, 1000);
        } else {
          loading.close();
          this.$message.error("重置密码失败！" + res.data.msg);
        }
      });
    },
    screeneeWidth() {
      if (window.innerWidth < 768) {
        this.dialogWidth = "80%";
      } else {
        this.dialogWidth = "30%";
      }
    },
  },
  created() {
    this.isLogin = localStorage.getItem("isLogin");
    this.username = localStorage.getItem("username");
    this.screeneeWidth();
    // this.quickAgentLogin();
    this.checkTokens();
  },
  beforeDestroy() {
    // 销毁组件时清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style>
button {
  background-color: #cb1414;
  color: white;
  border: 0;
  padding: 8px 20px;
  font-size: 15px;
  margin: 0 10px;
}

.bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.resetInput {
  background-color: rgba(255, 255, 255, 0.3);
  border: 0px;
  border-bottom: 0.5px #666666 solid;
  height: 30px;
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.resetInput:focus {
  border-bottom: 1px #cb1414 solid; /* 焦点状态下的边框颜色 */
  outline: none; /* 确保焦点时也没有outline */
}

@media screen and (max-width: 768px) {
  .agentLoginArea {
    padding: 30px !important;
  }
  .findPw {
    float: left !important;
  }
}
</style>
