import Vue from "vue";
import Router from "vue-router";
import HomeVue from "@/components/HomeVue.vue";
import LoginVue from "@/components/LoginVue.vue";
import RegVue from "@/components/RegVue.vue";
import ServerVue from "@/components/ServerVue.vue";
import AboutVue from "@/components/AboutVue.vue";
import ActivityVue from "@/components/ActivityVue.vue";
import CustomService from "@/components/CustomService.vue";
import AgentVue from "@/components/AgentVue.vue";
import AgentLogin from "@/components/AgentLogin.vue";
import NewsDetail from "@/components/NewsDetail.vue";
import ServerDetail from "@/components/ServerDetail.vue";
import PayVue from "@/components/PayVue.vue";
import YonoVue from "@/components/YonoVue.vue";
import CokeVue from "@/components/CokeVue.vue";
import DownVue from "@/components/DownVue.vue";
import CreateVue from "@/components/CreateVue.vue";
import CreateCokeVue from "@/components/CreateCokeVue.vue";
import AgentHome from "@/components/Agent/AgentHome.vue";
import YonoDoc from "@/components/YonoDoc.vue";
import CokeDoc from "@/components/CokeDoc.vue";
import YonoQa from "@/components/YonoQa.vue";
import JisuSchool from "@/components/JisuSchool.vue";
import ReportVue from "@/components/ReportVue.vue";
import VideoVue from "@/components/VideoVue.vue";
import UserCenter from "@/components/UserCenter.vue";
import DeliverDoc from "@/components/DeliverDoc.vue";
import ApiDoc from "@/components/ApiDoc.vue";

Vue.use(Router);

const router = new Router({
  routes: [
    { path: "/", component: HomeVue },
    { path: "/login", component: LoginVue },
    { path: "/reg", component: RegVue },
    { path: "/server", component: ServerVue },
    { path: "/about", component: AboutVue },
    { path: "/custom", component: CustomService },
    { path: "/agent", component: AgentVue },
    { path: "/agentLogin", component: AgentLogin },
    { path: "/news/:id", component: NewsDetail },
    { path: "/server/:id", component: ServerDetail },
    { path: "/pay", component: PayVue },
    { path: "/yono", component: YonoVue },
    { path: "/coke", component: CokeVue },
    { path: "/download", component: DownVue },
    { path: "/create", component: CreateVue },
    { path: "/createcoke", component: CreateCokeVue },
    { path: "/agentHome", component: AgentHome },
    { path: "/yonodoc", component: YonoDoc },
    { path: "/cokedoc", component: CokeDoc },
    { path: "/yonoqa", component: YonoQa },
    { path: "/jisucollege", component: JisuSchool },
    { path: "/report", component: ReportVue },
    { path: "/video", component: VideoVue },
    { path: "/user", component: UserCenter },
    { path: "/activity", component: ActivityVue },
    { path: "/yonodeliver", component: DeliverDoc },
    { path: "/apidoc", component: ApiDoc },
  ],
});

// router.beforeEach((to, from, next) => {
//   const isAgent = localStorage.getItem("Agent") === "true"; // 确保类型一致性，转换为布尔值
//   const isAgentLogin = localStorage.getItem("isAgentLogin") === "true"; // 确保类型一致性，转换为布尔值

//   if (isAgent) {
//     // 如果是代理
//     if (isAgentLogin) {
//       // 已登录的代理，跳转到代理首页
//       if (to.path !== "/agentHome") {
//         next("/agentHome");
//       } else {
//         next(); // 如果目标是代理首页，继续放行
//       }
//     } else {
//       // 未登录的代理，跳转到登录页面
//       if (to.path !== "/agentLogin") {
//         next("/agentLogin");
//       } else {
//         next(); // 如果目标是登录页面，继续放行
//       }
//     }
//   } else {
//     // 如果不是代理，正常放行
//     next();
//   }
// });

export default router;
