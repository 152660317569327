<template>
  <div>
    <el-row :gutter="20" v-loading="loading">
      <el-col
        :xs="24"
        :lg="12"
        :offset="0"
        v-for="item in completeGameData"
        :key="item.id || item.game_type"
      >
        <el-card style="margin-bottom: 10px">
          <div v-if="item.game_type === 'YonoGames'" style="display: flex">
            <img
              src="https://api.jisuqipai.online/uploads/yono.png"
              style="width: 50px; height: 50px; border-radius: 100px"
              alt="YonoGames"
            />
            <div>
              <p style="margin: 3px 10px">YonoGames海外竖屏版</p>
              <p v-if="item.orderno" style="margin: 3px 10px">
                订单编号：{{ item.orderno }}
              </p>
            </div>
          </div>
          <div v-if="item.game_type === 'CokeJogo'" style="display: flex">
            <img
              src="https://api.jisuqipai.online/uploads/coke.png"
              style="width: 50px; height: 50px; border-radius: 100px"
              alt="CokeJogo"
            />
            <div>
              <p style="margin: 3px 10px">CokeJogo出海版</p>
              <p v-if="item.orderno" style="margin: 3px 10px">
                订单编号：{{ item.orderno }}
              </p>
            </div>
          </div>
          <button
            v-if="item.orderno"
            @click="chooseMenu(104, item.orderno, item.game_type)"
            style="float: right; margin-bottom: 20px"
          >
            管理游戏
          </button>
          <button
            v-else
            @click="
              $router.push(item.game_type === 'YonoGames' ? '/yono' : '/coke')
            "
            style="float: right; margin-bottom: 20px"
          >
            去购买
          </button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getUserGameList } from "@/api/api";

export default {
  data() {
    return {
      gameData: [], // 从服务器返回的游戏数据
      loading: false,
    };
  },
  computed: {
    // 补全的游戏数据
    completeGameData() {
      const requiredGames = [
        { game_type: "YonoGames", orderno: null },
        { game_type: "CokeJogo", orderno: null },
      ];
      const existingGameTypes = this.gameData.map((item) => item.game_type);

      // 过滤出缺少的游戏类型
      const missingGames = requiredGames.filter(
        (game) => !existingGameTypes.includes(game.game_type)
      );

      // 合并已有游戏和缺少的游戏
      return [...this.gameData, ...missingGames];
    },
  },
  methods: {
    getUserGameLists() {
      this.loading = true;
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");

      getUserGameList(token, uid).then((res) => {
        if (res.data.code === 200) {
          this.gameData = res.data.data;
          this.loading = false;
          return;
        }
        this.$message.error("获取游戏列表失败！" + res.data.msg);
        this.loading = false;
      });
    },
    chooseMenu(menu, order, type) {
      this.$emit("custom-event", menu, order, type);
    },
  },
  created() {
    this.getUserGameLists();
  },
};
</script>
