<template>
  <div id="app">
    <!-- <AgentHome v-if="Agent === 'true'" /> -->
    <el-container style="padding: 0">
      <t-affix :offset-top="0" :offset-bottom="0" ref="affix">
        <el-header
          v-if="show === true"
          :style="{
            padding: '10px 0 80px 0',
            backgroundColor: backgroundColor,
            boxSizing: 'border-box',
          }"
        >
          <el-row :gutter="20">
            <el-col :xs="21" :sm="10" :md="8" :lg="6" class="headerLeftCol">
              <img
                v-if="agentData.level >= 2"
                src="@/assets/logo2.png"
                alt=""
                style="width: 200px; margin-left: 80px"
                @click="$router.push('/')"
              />
              <img
                v-else-if="agentData.level < 1"
                src="@/assets/logo1.png"
                alt=""
                style="width: 200px; margin-left: 80px"
                @click="$router.push('/')"
              />
              <img
                v-else
                src="@/assets/logo1.png"
                alt=""
                style="width: 200px; margin-left: 80px"
                @click="$router.push('/')"
              />
            </el-col>
            <el-col
              v-show="screenWidth > 1300"
              :xs="0"
              :lg="13"
              class="headerCenterCol"
            >
              <el-menu
                :default-active="indexMenu"
                class="el-menu-demo"
                mode="horizontal"
                :background-color="backgroundColor"
                :text-color="normalTextColor"
                :active-text-color="textColor"
              >
                <el-menu-item index="1" @click="$router.push('/')"
                  >首页</el-menu-item
                >
                <el-menu-item index="2" @click="$router.push('/activity')"
                  >活动</el-menu-item
                >
                <el-menu-item index="3" @click="$router.push('/server')"
                  >海外服务器</el-menu-item
                >
                <el-menu-item index="4" @click="$router.push('/custom')"
                  >定制服务</el-menu-item
                >
                <el-menu-item index="5" @click="$router.push('/jisucollege')"
                  >极速学院</el-menu-item
                >
                <el-menu-item index="6" @click="$router.push('/agent')"
                  >代理合作</el-menu-item
                >
                <el-menu-item index="7" @click="$router.push('/download')"
                  >极速APP</el-menu-item
                >
                <el-menu-item index="8" @click="$router.push('/about')"
                  >关于我们</el-menu-item
                >
              </el-menu>
            </el-col>
            <el-col
              v-show="screenWidth > 1300"
              :xs="0"
              :lg="5"
              :offset="0"
              style="line-height: 60px; float: right"
            >
              <div v-show="isLogin === 'false' || isLogin === null">
                <div class="btn" @click="$router.replace('/reg')">注册</div>
                <div
                  class="btn"
                  style="
                    margin-left: 10px;
                    padding-left: 15px;
                    background-color: black;
                    color: white;
                  "
                  @click="$router.replace('/login')"
                >
                  登录
                </div>
              </div>
              <div v-show="isLogin === 'true'">
                <el-popover placement="top-start" trigger="hover">
                  <div style="text-align: center">
                    <div style="display: flex">
                      <img
                        :src="avatar"
                        alt="avatar"
                        style="width: 50px; height: 50px; border-radius: 10px"
                      />
                      <div style="text-align: left; padding: 5px">
                        <span v-if="agentData.level === 1"> 银牌代理 </span>
                        <span v-if="agentData.level === 2">
                          尊敬的金牌代理
                        </span>
                        <span v-if="agentData.level === 3">
                          尊贵的钻石代理
                        </span>
                        <br />
                        <span style="font-family: 'Microsoft YaHei'">
                          {{ nickname }}
                        </span>
                        <br />
                        <span>{{ hi }}！</span>
                      </div>
                    </div>
                    <div
                      style="border-bottom: 0.3px #c0c4cc solid; margin: 7px 0"
                    ></div>
                    <div style="text-align: left">
                      <div
                        style="padding: 5px; cursor: pointer"
                        class="avatar-menu"
                        @click="$router.push('/user')"
                      >
                        <i class="el-icon-user"></i>
                        <span>个人中心</span>
                      </div>
                      <div
                        style="padding: 5px; cursor: pointer"
                        class="avatar-menu"
                        @click="$router.push('/pay')"
                      >
                        <i class="el-icon-money"></i>
                        <span>账户充值</span>
                      </div>
                      <div
                        style="padding: 5px; cursor: pointer"
                        class="avatar-menu"
                        @click="changePwdDialog = true"
                      >
                        <i class="el-icon-set-up"></i>
                        <span>修改密码</span>
                      </div>
                      <div
                        style="padding: 5px; cursor: pointer"
                        class="avatar-menu"
                        @click="logOut()"
                      >
                        <i class="el-icon-switch-button"></i>
                        <span>退出登录</span>
                      </div>
                    </div>
                    <!-- <div
                    style="
                      background-color: #0052d9;
                      color: white;
                      padding: 6px 10px;
                      margin-top: 5px;
                      cursor: pointer;
                    "
                    @click="$router.push('/user')"
                  >
                    个人中心
                  </div> -->
                    <!-- <div
                    style="
                      background-color: #cb1414;
                      color: white;
                      padding: 6px 10px;
                      margin-top: 5px;
                      cursor: pointer;
                    "
                    @click="logOut()"
                  >
                    退出登录
                  </div> -->
                    <!-- <div
                    style="
                      background-color: #666666;
                      color: white;
                      padding: 6px 10px;
                      margin-top: 5px;
                      cursor: pointer;
                    "
                    @click="changePwdDialog = true"
                  >
                    修改密码
                  </div> -->
                    <!-- <div
                    style="
                      background-color: #cb1414;
                      color: white;
                      padding: 6px 10px;
                      cursor: pointer;
                      margin-top: 5px;
                    "
                    @click="$router.push('/pay')"
                  >
                    USDT充值
                  </div> -->
                  </div>
                  <img
                    :src="avatar"
                    alt="avatar"
                    slot="reference"
                    @click="$router.push('/user')"
                    style="
                      height: 40px;
                      width: 40px;
                      border-radius: 50px;
                      margin-top: 10px;
                      cursor: pointer;
                    "
                  />
                </el-popover>
              </div>
              <!-- <el-button type="danger" style="margin-left: 10px">注册</el-button>
            <el-button @click="$router.push('/login')">登录</el-button> -->
            </el-col>
            <el-col :xs="3" :sm="6" :md="6" :lg="0" style="float: right">
              <img
                @click="phoneDrawer = true"
                src="@/assets/menu.png"
                alt=""
                style="width: 35px; height: 50px; padding-top: 15px"
              />
            </el-col>
          </el-row>
          <t-drawer
            :visible.sync="phoneDrawer"
            cancelBtn=""
            size="30vw"
            :footer="false"
          >
            <div
              v-show="isLogin === 'false' || isLogin === null"
              style="padding-right: 6vw"
            >
              <button
                class="btn"
                style="width: 100%"
                @click="$router.replace('/reg')"
              >
                注册
              </button>
              <button
                class="btn"
                style="
                  background-color: black;
                  color: white;
                  width: 100%;
                  margin-top: 10px;
                "
                @click="$router.replace('/login')"
              >
                登录
              </button>
            </div>
            <div v-show="isLogin === 'true'">
              <el-popover placement="top-start" trigger="hover">
                <div style="text-align: center">
                  {{ hi }}
                  <span style="font-weight: bolder; font-style: oblique">{{
                    nickname
                  }}</span>
                  <div
                    style="
                      background-color: #0052d9;
                      color: white;
                      padding: 6px 10px;
                      margin-top: 5px;
                      cursor: pointer;
                    "
                    @click="$router.push('/user')"
                  >
                    个人中心
                  </div>
                  <div
                    style="
                      background-color: #cb1414;
                      color: white;
                      padding: 6px 10px;
                      margin-top: 5px;
                      cursor: pointer;
                    "
                    @click="logOut()"
                  >
                    退出登录
                  </div>
                  <div
                    style="
                      background-color: #666666;
                      color: white;
                      padding: 6px 10px;
                      margin-top: 5px;
                      cursor: pointer;
                    "
                    @click="changePwdDialog = true"
                  >
                    修改密码
                  </div>
                  <div
                    style="
                      background-color: #cb1414;
                      color: white;
                      padding: 6px 10px;
                      cursor: pointer;
                      margin-top: 5px;
                    "
                    @click="$router.push('/pay')"
                  >
                    充值
                  </div>
                </div>
                <img
                  :src="avatar"
                  alt="avatar"
                  slot="reference"
                  @click="$router.push('/user')"
                  style="
                    width: 5rem;
                    border-radius: 100px;
                    height: 5rem;
                    margin-left: 10px;
                    cursor: pointer;
                  "
                />
              </el-popover>
            </div>
            <el-menu
              :default-active="indexMenu"
              class="el-menu-demo"
              mode="horizontal"
              active-text-color="#cb1414"
            >
              <el-menu-item index="1" @click="$router.push('/')"
                >首页</el-menu-item
              >
              <el-menu-item index="2" @click="$router.push('/activity')"
                >活动</el-menu-item
              >
              <el-menu-item index="3" @click="$router.push('/server')"
                >海外服务器</el-menu-item
              >
              <el-menu-item index="4" @click="$router.push('/custom')"
                >定制服务</el-menu-item
              >
              <el-menu-item index="5" @click="$router.push('/jisucollege')"
                >极速学院</el-menu-item
              >
              <el-menu-item index="6" @click="$router.push('/agent')"
                >代理合作</el-menu-item
              >
              <el-menu-item index="7" @click="$router.push('/download')"
                >极速APP</el-menu-item
              >
              <el-menu-item index="8" @click="$router.push('/about')"
                >关于我们</el-menu-item
              >
            </el-menu>
          </t-drawer>
        </el-header>
      </t-affix>
      <el-main style="padding: 0">
        <router-view @show="handleMessage()"></router-view>
        <div
          v-if="show === true"
          style="
            position: fixed;
            display: flex;
            flex-direction: column;
            bottom: 0;
            right: 0;
            z-index: 99;
            margin-bottom: 100px;
            margin-right: 10px;
          "
        >
          <a
            target="_blank"
            :href="
              `tencent://message/?uin=123456789` + qq + `&site=qq&menu=yes`
            "
          >
            <img
              src="@/assets/QQ.png"
              alt=""
              style="
                width: 60px;
                height: 60px;
                background-color: white;
                border-radius: 100px;
                padding: 10px;
              "
            />
          </a>
          <a target="_blank" :href="`https://t.me/` + telegram">
            <img
              src="@/assets/telegram.png"
              alt=""
              style="width: 60px; height: 60px; margin-top: 20px"
            />
          </a>
        </div>
      </el-main>
      <el-footer class="footer" v-if="show === true">
        Copyright &copy; 极速棋牌 All Rights Reserved.
        <div class="dau">
          <p style="margin: 0">当前网站日访问人数：{{ statics.dau }}</p>
          <p style="margin: 0 10px">月访问人数：{{ statics.mau }}</p>
          <p style="margin: 0">当前访问ip：{{ statics.ip }}</p>
        </div>
      </el-footer>
    </el-container>
    <!-- 修改密码弹窗 -->
    <el-dialog
      title="修改密码"
      :visible.sync="changePwdDialog"
      width="30%"
      center
    >
      <el-form>
        <el-form-item label="请输入邮箱:" style="width: 100%">
          <el-input v-model="reset.email">
            <template slot="suffix">
              <el-button
                v-if="countdown === 0"
                type="text"
                style="color: #cb1414"
                @click="getMailCaptchas()"
                >发送验证码</el-button
              >
              <el-button
                v-if="countdown != 0"
                type="text"
                style="color: #cb1414; cursor: not-allowed"
                >{{ countdown }}后可再次发送</el-button
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="请输入验证码:" style="width: 100%">
          <el-input v-model="reset.pwd"></el-input>
        </el-form-item>
        <el-form-item label="请输入密码:" style="width: 100%">
          <el-input v-model="reset.captcha"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button @click="changePwdDialog = false">取 消</button>
        <button type="primary" @click="resetpwds()">确 定</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  checkUpgrade,
  checkUserAgent,
  checkUserStatus,
  getMailCaptcha,
  resetpwd,
  statistics,
} from "./api/api";
// import AgentHome from "./components/Agent/AgentHome.vue";
export default {
  // components: {
  //   AgentHome,
  // },
  data() {
    return {
      centerDialogVisible: false,
      isLogin: false,
      avatar: "",
      username: "",
      nickname: "",
      mobile: "",
      currentTime: new Date(), // 初始化当前时间
      hi: "",
      phoneDrawer: false,
      statics: [],
      Agent: false,
      changePwdDialog: false,
      reset: [],
      event: "resetpwd",
      countdown: 0,
      telegram: "yliang103",
      qq: "3471043983",
      show: true,
      agentData: [],
      backgroundColor: "#fff",
      pageBackgroundColor: "#f1f5f8",
      textColor: "#cb1414",
      normalTextColor: "#909399",
      indexMenu: "1",
      screenWidth: window.innerWidth,
    };
  },
  methods: {
    tiaozhuan() {
      var ua = navigator.userAgent;
      var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
        isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/);
      // isMobile = isIphone || isAndroid;
      if (isIphone) {
        location.href =
          "mqq://card/show_pslcard?src_type=internal&version=1&uin=3139926636&card_type=person&source=sharecard";
      } else if (isAndroid) {
        location.href =
          "mqq://card/show_pslcard?src_type=internal&version=1&uin=3139926636&card_type=person&source=sharecard";
      } else {
        location.href =
          "tencent://AddContact/?fromId=45&fromSubId=1&subcmd=all&uin=3139926636";
      }
    },
    checkUserAgents() {
      const loading = this.$loading({
        lock: true,
        text: "正在全力加载极速棋牌",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      checkUserAgent(token, uid).then((res) => {
        if (res.data.code === 200) {
          this.agentData = res.data.data;
          if (this.agentData.level > 1) {
            this.textColor = "#ebbd4f";
            this.backgroundColor = "#212121";
            this.pageBackgroundColor = "#313131";
          }
          loading.close();
        } else {
          // this.$message({
          //   type: "error",
          //   message: res.data.msg,
          // });
          loading.close();
        }
      });
    },
    handleMessage() {
      this.show = false;
    },
    resetpwds() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      resetpwd(this.reset.email, this.reset.captcha, this.reset.pwd)
        .then((res) => {
          if (res.data.code === 1) {
            this.$message({
              message: "重置成功，即将推出登录",
              type: "success",
            });
            this.changePwdDialog = false;
            this.logOut();
            loading.close();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
            loading.close();
          }
        })
        .catch((err) => {
          this.$message({
            message: err.data.msg,
            type: "error",
          });
          this.changePwdDialog = false;
          loading.close();
        });
    },
    getMailCaptchas() {
      if (this.reset.email) {
        const loading = this.$loading({
          lock: true,
          text: "正在加载",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        getMailCaptcha(this.reset.email, this.event)
          .then((res) => {
            if (res.data.code === 200) {
              this.$message.success("发送成功！请查看您的邮箱");
              // 启动倒计时，60秒倒计时
              this.countdown = 60;
              this.timer = setInterval(() => {
                if (this.countdown > 0) {
                  this.countdown--;
                } else {
                  clearInterval(this.timer); // 倒计时结束时清除定时器
                }
              }, 1000);
              loading.close();
            } else {
              this.$message.error(res.data.msg);
              loading.close();
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg);
            loading.close();
          });
      } else {
        this.$message.error("请输入邮箱");
      }
    },
    greetingMessage() {
      const hours = this.currentTime.getHours(); // 获取当前的小时数
      // 根据小时数判断问候语
      if (hours < 12) {
        this.hi = "早上好";
      } else if (hours < 14) {
        this.hi = "中午好";
      } else if (hours < 18) {
        this.hi = "下午好";
      } else {
        this.hi = "晚上好";
      }
    },
    logOut() {
      localStorage.removeItem("username");
      localStorage.removeItem("nickname");
      localStorage.removeItem("mobile");
      localStorage.removeItem("avatar");
      localStorage.removeItem("token");
      localStorage.removeItem("uid");
      localStorage.removeItem("loginTime");
      localStorage.setItem("isLogin", "false");
      this.$message.success("退出登录成功！即将刷新页面");
      setTimeout(() => {
        location.reload();
      }, 1000);
    },
    // 日活月活访问
    statistic() {
      statistics();
    },
    checkUpgrades() {
      const username = localStorage.getItem("username");
      checkUpgrade(username).then((res) => {
        if (res.data.code === 200 && res.data.data != null) {
          this.telegram = res.data.data.telegram;
          this.qq = res.data.data.qq;
        }
      });
    },
    checkUserStatu() {
      console.log("检查当前用户状态");
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      if (!uid) {
        this.$message.error("无法获取当前用户UID，请重新登录！");
        setTimeout(() => {
          this.logOut();
        }, 1000);
      }
      checkUserStatus(token, uid).then((res) => {
        if (res.data.code === 200) {
          if (res.data.data === "normal") {
            console.log("账号状态正常！");
          } else if (res.data.data === "ban") {
            this.$message.error("您的账号已被封禁！请联系您的上级处理！");
            setTimeout(() => {
              this.logOut();
            }, 1000);
          } else {
            this.$message.error("您的账号状态异常！请联系您的上级处理！");
            setTimeout(() => {
              this.logOut();
            }, 1000);
          }
        } else {
          this.$message.error("账号检查状态失败！将在下次登录时重新检查！");
        }
      });
    },
    updateIndexMenu(path) {
      if (path === "/") {
        this.indexMenu = "1";
      } else if (path === "/activity") {
        this.indexMenu = "2";
      } else if (path === "/server") {
        this.indexMenu = "3";
      } else if (path === "/custom") {
        this.indexMenu = "4";
      } else if (path === "/jisucollege") {
        this.indexMenu = "5";
      } else if (path === "/agent") {
        this.indexMenu = "6";
      } else if (path === "/download") {
        this.indexMenu = "7";
      } else if (path === "/about") {
        this.indexMenu = "8";
      }
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth; // 更新屏幕宽度
    },
  },
  mounted() {
    this.updateIndexMenu(this.currentPath); // 在页面加载时手动调用
    window.addEventListener("resize", this.updateScreenWidth); // 监听窗口大小变化
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenWidth); // 移除监听器
  },
  created() {
    this.Agent = localStorage.getItem("Agent");
    this.greetingMessage();
    this.isLogin = localStorage.getItem("isLogin");
    if (this.isLogin === "true") {
      this.avatar = localStorage.getItem("avatar");
      this.username = localStorage.getItem("username");
      this.nickname = localStorage.getItem("nickname");
      this.mobile = localStorage.getItem("mobile");
      this.checkUpgrades();
      this.checkUserAgents();
      const Agent = localStorage.getItem("Agent");
      if (Agent === true) {
        this.checkUserStatu();
      }
    } else {
      console.log("is not login");
    }
    // this.statistic();
  },
  computed: {
    currentPath() {
      return this.$route.path; // 获取当前路由的路径
    },
  },
  watch: {
    currentPath(newPath) {
      this.updateIndexMenu(newPath); // 抽离出逻辑到单独的方法
    },
  },
};
</script>

<style>
.btn {
  background-color: #cb1414;
  color: white;
  display: inline;
  padding: 5px 20px;
  text-align: center;
  border-radius: 2px;
  cursor: default;
}

.headerLeftCol {
  text-align: center;
  padding-top: 15px;
}

.headerCenterCol {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .headerLeftCol {
    text-align: center;
    padding-left: 0;
  }
  .headerLeftCol img {
    margin-left: 50px;
  }
  .headerCenterCol {
    display: none;
  }
  .footer {
    padding: 20px 0 130px 0 !important;
    text-align: center;
    background-color: white;
  }

  .footer .dau {
    /*  display: block;  */
    display: none;
  }
}

.dau {
  /* display: flex; */
  display: none;
}

.footer {
  padding: 50px 50px 70px 150px;
  color: #666666;
}

.avatar-menu {
  font-size: 15px !important;
  margin-right: 10px;
  transition: all 0.3s ease;
}

.avatar-menu:hover {
  background-color: #cb1414;
  color: white;
}

.t-drawer__body {
  padding: 0;
}

body {
  overflow-x: hidden;
}
</style>
