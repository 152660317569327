<template>
  <div>
    <el-menu
      default-active="1"
      mode="horizontal"
      background-color="#f1f5f8"
      text-color="#000"
      active-text-color="#fb1414"
      style="margin: 0 150px"
    >
      <el-menu-item index="1" @click="showMenu = 1">
        <img
          src="@/assets/paypal_logo.png"
          alt="paypal"
          style="width: 30px; height: 30px"
        />
        PayPal
      </el-menu-item>
      <el-menu-item index="2" @click="showMenu = 2">
        <img
          src="@/assets/usdt_logo.png"
          alt="usdt"
          style="width: 30px; height: 30px"
        />USDT</el-menu-item
      >
    </el-menu>
    <!-- PayPal -->
    <div v-if="showMenu === 1" class="pay_main">
      <!-- 套餐选择 -->
      <div class="money_box">
        <div v-for="item in moneys" :key="item.id">
          <div
            class="moneyBtn"
            :class="{ active: money === item.money }"
            @click="money = item.money"
          >
            {{ item.money }}￥
            <div v-if="money === item.money" class="triangle"></div>
          </div>
        </div>
      </div>
      <!-- 自定义输入 -->
      <div style="margin-top: 10px">
        <span style="color: #606266">其他金额：</span>
        <!-- <input
          type="text"
          style="
            background-color: transparent;
            border: 1px #606266 solid;
            border-radius: 2px;
          "
        /> -->
        <el-input-number
          v-model="money"
          controls-position="right"
          :min="500"
          :max="99999999"
        ></el-input-number>
      </div>
      <!-- 充值方式 -->
      <div style="display: flex">
        <p style="color: #606266; margin: 30px 0px">充值方式：</p>
        <el-radio-group v-model="radio">
          <el-radio :label="0"
            ><img
              src="@/assets/paypal.png"
              alt="paypal"
              style="
                height: 50px;
                width: 130px;
                transform: translateY(20px);
                border: 1px #909399 solid;
                border-radius: 2px;
              "
          /></el-radio>
        </el-radio-group>
      </div>
      <!-- 应付金额 -->
      <div style="margin-top: 30px">
        <span style="color: #606266">应付金额：</span>
        <span
          v-if="money < 5000"
          style="color: #cb1414; font-weight: bolder; font-size: 30px"
          >{{ money }} + {{ money * 0.03 }} CNY</span
        >
        <span
          v-if="money >= 5000"
          style="color: #cb1414; font-weight: bolder; font-size: 30px"
          >{{ money }} CNY</span
        >
        <el-popover placement="top-start" trigger="hover">
          <p>此计算结果不代表最终支付价格，实际支付价格以支付页面为准</p>
          <p>
            USDT实时汇率来自币圈子数据网，当前汇率：<span
              v-loading="rateLoading"
              >{{ rate }}</span
            >
            <a
              href="https://www.120btc.com/coin/30679.html"
              target="_blank"
              style="color: blue; margin-left: 10px"
              >相关链接</a
            >
          </p>
          <span
            v-if="money < 5000"
            slot="reference"
            v-loading="rateLoading"
            style="color: #606266"
          >
            ≈ {{ ((money + money * 0.03) / rate).toFixed(2) }} USDT</span
          >
          <span
            v-if="money >= 5000"
            slot="reference"
            v-loading="rateLoading"
            style="color: #606266"
          >
            ≈ {{ (money / rate).toFixed(2) }} USDT</span
          >
        </el-popover>
        <p v-if="money < 5000" style="color: #606266; margin: 10px 0">
          极速平台充值5000CNY以下收取3%手续费，单笔充值大于5000CNY免手续费
        </p>
      </div>
      <el-divider></el-divider>
      <!-- 确认支付 -->
      <button
        style="
          background-color: #cb1414;
          border-radius: 2px;
          color: white;
          border: 0;
          padding: 10px 20px;
        "
        v-loading="rateLoading"
        @click="createPaypalPaymentUrls()"
      >
        确认支付
      </button>
      <span style="font-size: 13px; color: #909399; margin-left: 20px"
        >温馨提示：充值CNY后可以直接在极速平台消费，系统有一定的延迟，充值成功后大约1-2分钟到账，充值遇到问题请及时与客服联系。</span
      >
    </div>
    <!-- USDT -->
    <div v-if="showMenu === 2" class="pay_main">
      <!-- 套餐选择 -->
      <div class="money_box">
        <div v-for="item in moneys" :key="item.id">
          <div
            class="moneyBtn"
            :class="{ active: money === item.money }"
            @click="money = item.money"
          >
            {{ item.money }}￥
            <div v-if="money === item.money" class="triangle"></div>
          </div>
        </div>
      </div>
      <!-- 自定义输入 -->
      <div style="margin-top: 10px">
        <span style="color: #606266">其他金额：</span>
        <!-- <input
          type="text"
          style="
            background-color: transparent;
            border: 1px #606266 solid;
            border-radius: 2px;
          "
        /> -->
        <el-input-number
          v-model="money"
          controls-position="right"
          :min="500"
          :max="99999999"
        ></el-input-number>
      </div>
      <!-- 充值方式 -->
      <div style="display: flex">
        <p style="color: #606266; margin: 30px 0px">充值方式：</p>
        <el-radio-group v-model="radio">
          <el-radio :label="0"
            ><img
              src="@/assets/trc20.png"
              alt="trc20"
              style="
                height: 50px;
                width: 130px;
                transform: translateY(20px);
                border: 1px #909399 solid;
                border-radius: 2px;
              "
          /></el-radio>
        </el-radio-group>
      </div>
      <!-- 应付金额 -->
      <div style="margin-top: 30px">
        <span style="color: #606266">应付金额：</span>
        <span
          v-if="money < 5000"
          style="color: #cb1414; font-weight: bolder; font-size: 30px"
          >{{ money }} + {{ money * 0.03 }} CNY</span
        >
        <span
          v-if="money >= 5000"
          style="color: #cb1414; font-weight: bolder; font-size: 30px"
          >{{ money }} CNY</span
        >
        <el-popover placement="top-start" trigger="hover">
          <p>此计算结果不代表最终支付价格，实际支付价格以支付页面为准</p>
          <p>
            USDT实时汇率来自币圈子数据网，当前汇率：<span
              v-loading="rateLoading"
              >{{ rate }}</span
            >
            <a
              href="https://www.120btc.com/coin/30679.html"
              target="_blank"
              style="color: blue; margin-left: 10px"
              >相关链接</a
            >
          </p>
          <span
            v-if="money < 5000"
            slot="reference"
            v-loading="rateLoading"
            style="color: #606266"
          >
            ≈ {{ ((money + money * 0.03) / rate).toFixed(2) }} USDT</span
          >
          <span
            v-if="money >= 5000"
            slot="reference"
            v-loading="rateLoading"
            style="color: #606266"
          >
            ≈ {{ (money / rate).toFixed(2) }} USDT</span
          >
        </el-popover>
        <p v-if="money < 5000" style="color: #606266; margin: 10px 0">
          极速平台充值5000CNY以下收取3%手续费，单笔充值大于5000CNY免手续费
        </p>
      </div>
      <el-divider></el-divider>
      <!-- 确认支付 -->
      <button
        style="
          background-color: #cb1414;
          border-radius: 2px;
          color: white;
          border: 0;
          padding: 10px 20px;
        "
        v-loading="rateLoading"
        @click="usdtPays()"
      >
        确认支付
      </button>
      <span style="font-size: 13px; color: #909399; margin-left: 20px"
        >温馨提示：充值CNY后可以直接在极速平台消费，系统有一定的延迟，充值成功后大约1-2分钟到账，充值遇到问题请及时与客服联系。</span
      >
    </div>
  </div>
</template>

<script>
import { getUSDTrate, usdtPay, createPaypalPaymentUrl } from "@/api/api";
export default {
  data() {
    return {
      radio: 0,
      money: 500,
      moneys: [
        { id: 1, money: 500 },
        { id: 2, money: 1000 },
        { id: 3, money: 2000 },
        { id: 4, money: 5000 },
        { id: 5, money: 10000 },
        { id: 6, money: 20000 },
        { id: 7, money: 30000 },
        { id: 8, money: 50000 },
        { id: 9, money: 100000 },
        { id: 10, money: 200000 },
      ],
      totalMoney: 0,
      rate: 0,
      rateLoading: false,
      showMenu: 1,
    };
  },
  methods: {
    createPaypalPaymentUrls() {
      const loading = this.$loading({
        lock: true,
        text: "正在创建订单",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      const money = this.money;
      if (money < 5000) {
        this.totalMoney = ((money + money * 0.03) / this.rate).toFixed(2);
      } else if (money >= 5000) {
        this.totalMoney = (money * this.rate).toFixed(2);
      }
      createPaypalPaymentUrl(token, uid, this.totalMoney, this.money).then(
        (res) => {
          if (res.data.code === 1) {
            this.$message.success("订单创建成功，正在前往支付页面");
            loading.close();
            window.open(res.data.data);
            return;
          }
          this.$message.error("订单创建失败" + res.data.msg);
          loading.close();
        }
      );
    },
    getUSDTrates() {
      this.rateLoading = true;
      getUSDTrate().then((res) => {
        if (res.data.code === 200) {
          this.rate = res.data.data;
          this.rateLoading = false;
          return;
        }
        this.rateLoading = false;
      });
    },
    usdtPays() {
      if (this.money != 0 && this.money != null && this.money != "") {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const token = localStorage.getItem("token");
        const money = this.money;
        if (money < 5000) {
          this.totalMoney = ((money + money * 0.03) / this.rate).toFixed(2);
        } else if (money >= 5000) {
          this.totalMoney = (money * this.rate).toFixed(2);
        }
        usdtPay(this.totalMoney, token).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("正在跳转");
            window.open(res.data.data.url);
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请先选择或填写需要支付的金额！");
      }
    },
  },
  created() {
    const token = localStorage.getItem("token");
    if (token == null || token == "") {
      this.$message.error("请先登录！");
      this.$router.push("/login");
    }
    this.getUSDTrates();
  },
};
</script>

<style>
.pay_main {
  margin: 20px 150px;
}

.money_box {
  display: flex;
}

.moneyBtn {
  display: inline-block;
  border: 1px #606266 solid;
  border-radius: 2px;
  padding: 5px 20px;
  color: #606266;
  margin-right: 20px;
  position: relative;
}

.moneyBtn.active {
  border: 1px #cb1414 solid;
  color: #cb1414;
}

.moneyBtn .triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-bottom: 15px solid #cb1414;
  position: absolute;
  bottom: 0;
  right: 0;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #cb1414 !important;
  background: #cb1414 !important;
}

@media screen and (max-width: 768px) {
  .pay_main {
    margin: 20px 10px;
  }
  .money_box {
    display: flex;
    width: 100%;
    overflow: auto;
  }
}
</style>
