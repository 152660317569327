<template>
  <div>
    <!-- banner -->
    <div class="agentBanner" style="padding-top: 40px">
      <h1 style="margin: 0; font-size: 50px">开始合作</h1>
      <h1 style="margin: 0; font-size: 40px; font-weight: normal">
        成为极速代理
      </h1>
      <h1
        style="
          margin: 0;
          font-size: 30px;
          margin-bottom: 30px;
          font-weight: normal;
        "
      >
        为海内外合法棋牌运营商提供线上平台服务
      </h1>
      <div
        class="btn"
        style="font-size: 25px; padding: 10px 30px"
        @click="$router.push('/agentLogin')"
      >
        代理登录入口
      </div>
    </div>
    <div class="agentTitle">
      <h1 style="font-weight: normal">极速棋牌代理商合作条件</h1>
      <el-row :gutter="20" style="width: 100%; margin: 0px">
        <el-col :xs="12" :lg="5" class="partner"
          ><el-card
            ><div>
              <img
                src="@/assets/safe.png"
                alt=""
                style="width: 100px; height: 100px"
              />
              <p style="margin: 0">认可极速棋牌</p>
              <p style="margin: 0">产品和文化</p>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="12" :lg="5" class="partner"
          ><el-card
            ><div>
              <img
                src="@/assets/customer.png"
                alt=""
                style="width: 100px; height: 100px"
              />
              <p style="margin: 0">追求为客户</p>
              <p style="margin: 0">创造价值为前提</p>
            </div></el-card
          ></el-col
        >
        <el-col :xs="12" :lg="5" class="partner"
          ><el-card
            ><div>
              <img
                src="@/assets/exp.png"
                alt=""
                style="width: 100px; height: 100px"
              />
              <p style="margin: 0">具有丰富的</p>
              <p style="margin: 0">互联网产品销售经验</p>
            </div></el-card
          ></el-col
        >
        <el-col :xs="12" :lg="5" class="partner"
          ><el-card
            ><div>
              <img
                src="@/assets/team.png"
                alt=""
                style="width: 100px; height: 100px"
              />
              <p style="margin: 0">拥有一定规模的</p>
              <p style="margin: 0">和有经验的销售团队</p>
            </div></el-card
          ></el-col
        >
        <el-col :xs="24" :lg="5" class="partner"
          ><el-card
            ><div>
              <img
                src="@/assets/deamon1.png"
                alt=""
                style="width: 100px; height: 100px"
              />
              <p style="margin: 0">具备一定优质的</p>
              <p style="margin: 0">客户和当地资源</p>
            </div></el-card
          ></el-col
        >
      </el-row>
      <h1 style="font-weight: normal; margin-top: 50px">
        极速棋牌代理级别与权益
      </h1>
      <el-row gutter="20" style="margin: 10px 0">
        <el-col :xs="12" :lg="6">
          <el-card>
            <img
              src="@/assets/Sliver.png"
              alt=""
              style="width: 120px; height: 100px"
            />
            <div>
              <p style="margin: 0">下级客户无门槛限制</p>
              <p style="margin: 0">享受20%返点</p>
              <p style="margin: 0">免费开通银牌合作伙伴权限</p>
              <div class="block">
                <p style="margin: 0">&nbsp;</p>
                <p style="margin: 0">&nbsp;</p>
              </div>
            </div>
            <button
              v-if="isAgent === 1"
              class="agentBtn"
              style="margin-top: 10px"
              @click="$router.push('/agentlogin')"
            >
              前往代理入口
            </button>
            <button
              v-else
              class="agentBtn"
              style="margin-top: 10px"
              @click="openUpGradeDialog(0)"
            >
              注册成为代理
            </button>
          </el-card>
        </el-col>
        <el-col :xs="12" :lg="6">
          <el-card>
            <img
              src="@/assets/Gold.png"
              alt=""
              style="width: 120px; height: 100px"
            />
            <div>
              <p style="margin: 0">
                下级客户消费5000U以上可以免费升级为金牌合作伙伴
              </p>
              <p style="margin: 0">享受30%返点</p>
              <p style="margin: 0">或者支付500U直接升级成为金牌合作伙伴权限</p>
            </div>
            <button
              v-if="isAgent === 1 && agentData.level === 2"
              class="agentBtn"
              style="margin-top: 10px"
              @click="$router.push('/agentLogin')"
            >
              前往代理入口
            </button>
            <button
              v-else
              class="agentBtn"
              style="margin-top: 10px"
              @click="openUpGradeDialog(1)"
            >
              注册成为代理/升级
            </button>
          </el-card>
        </el-col>
        <el-col :xs="12" :lg="6">
          <el-card>
            <img
              src="@/assets/platinum.png"
              alt=""
              style="width: 120px; height: 100px"
            />
            <div>
              <p style="margin: 0">
                下级客户消费20000U以上可以免费升级白金合作伙伴
              </p>
              <p style="margin: 0">享受40%返点</p>
              <p style="margin: 0">或者支付2000直接升级成为白金合作伙伴权限</p>
            </div>
            <button
              v-if="isAgent === 1 && agentData.level === 3"
              class="agentBtn"
              style="margin-top: 10px"
              @click="$router.push('/agentLogin')"
            >
              前往代理入口
            </button>
            <button
              v-else
              class="agentBtn"
              style="margin-top: 10px"
              @click="openUpGradeDialog(2)"
            >
              注册成为代理/升级
            </button>
          </el-card>
        </el-col>
        <el-col :xs="12" :lg="6">
          <el-card>
            <img
              src="@/assets/deamon.png"
              alt=""
              style="width: 120px; height: 100px"
            />
            <div>
              <p style="margin: 0">
                下级客户消费50000U以上可以免费升级为钻石合作伙伴
              </p>
              <p style="margin: 0">享受50%返点</p>
              <p style="margin: 0">或者支付5000U直接升级成为钻石合作伙伴权限</p>
            </div>
            <button
              v-if="isAgent === 1 && agentData.level === 4"
              class="agentBtn"
              style="margin-top: 10px"
              @click="$router.push('/agentLogin')"
            >
              前往代理入口
            </button>
            <button
              v-else
              class="agentBtn"
              style="margin-top: 10px"
              @click="openUpGradeDialog(3)"
            >
              注册成为代理/升级
            </button>
          </el-card>
        </el-col>
      </el-row>
      <!-- <h1 style="font-weight: normal; margin-top: 50px">
        极速棋牌代理月榜排名
      </h1>
      <div style="color: white; line-height: 35px">
        <el-row style="color: black; font-weight: bolder">
          <el-col :span="8">名次</el-col>
          <el-col :span="8">玩家昵称</el-col>
          <el-col :span="8">段位</el-col>
        </el-row>
        <el-row style="background-color: #ee6401">
          <el-col :span="8" :offset="0">
            <img
              src="@/assets/vip1.png"
              alt=""
              style="height: 20px; width: 20px; transform: translateX(-50px)"
            />第一名</el-col
          >
          <el-col :span="8" :offset="0">Legend、小雨</el-col>
          <el-col :span="8" :offset="0">钻石代理</el-col>
        </el-row>
        <el-row style="background-color: #3d98e1">
          <el-col :span="8" :offset="0">
            <img
              src="@/assets/vip1.png"
              alt=""
              style="height: 20px; width: 20px; transform: translateX(-50px)"
            />第二名</el-col
          >
          <el-col :span="8" :offset="0">LD、TAKUMI</el-col>
          <el-col :span="8" :offset="0">钻石代理</el-col>
        </el-row>
        <el-row style="background-color: #837e7b">
          <el-col :span="8" :offset="0">
            <img
              src="@/assets/vip1.png"
              alt=""
              style="height: 20px; width: 20px; transform: translateX(-50px)"
            />第三名</el-col
          >
          <el-col :span="8" :offset="0">YGI托比马歌尔</el-col>
          <el-col :span="8" :offset="0">钻石代理</el-col>
        </el-row>
        <el-row style="background-color: #ececec; color: black">
          <el-col :span="8" :offset="0">
            <img
              src="@/assets/vip1.png"
              alt=""
              style="height: 20px; width: 20px; transform: translateX(-50px)"
            />第四名</el-col
          >
          <el-col :span="8" :offset="0">飞哥精神</el-col>
          <el-col :span="8" :offset="0">黄金代理</el-col>
        </el-row>
        <el-row style="background-color: white; color: black">
          <el-col :span="8" :offset="0">
            <img
              src="@/assets/vip1.png"
              alt=""
              style="height: 20px; width: 20px; transform: translateX(-50px)"
            />第五名</el-col
          >
          <el-col :span="8" :offset="0">YG能猫叶星辰</el-col>
          <el-col :span="8" :offset="0">黄金代理</el-col>
        </el-row>
        <el-row style="background-color: #ececec; color: black">
          <el-col :span="8" :offset="0">
            <img
              src="@/assets/vip1.png"
              alt=""
              style="height: 20px; width: 20px; transform: translateX(-50px)"
            />第六名</el-col
          >
          <el-col :span="8" :offset="0">斗鱼TV、LHJ</el-col>
          <el-col :span="8" :offset="0">黄金代理</el-col>
        </el-row>
      </div> -->
    </div>
    <!-- 升级弹窗 -->
    <el-dialog
      title="注册/升级代理"
      :visible.sync="upgradeVisible"
      width="30%"
      center
    >
      <!-- <p>
        您正在为当前用户
        <span style="font-weight: bold; font-style: italic">{{
          username
        }}</span>
        升级
      </p>
      <p>
        升级等级为
        <span v-if="level === 1">金牌代理</span>
        <span v-if="level === 2">白金代理</span>
        <span v-if="level === 3">钻石代理</span>
      </p>
      <p>请选择升级方式：</p> -->
      <!-- <el-radio-group v-model="type">
        <el-radio :label="1">通过业绩升级</el-radio>
        <el-radio :label="2">通过USDT升级</el-radio>
      </el-radio-group> -->
      <!-- <p
        v-if="type === 2"
        style="
          font-weight: bolder;
          font-style: italic;
          float: right;
          cursor: pointer;
          color: #cb1414;
        "
        @click="$router.push('/pay')"
      >
        前往充值USDT余额
      </p> -->
      <div style="text-align: center">
        <button class="beAgentBtn" @click="chooseType(1)">免费升级</button>
        <button class="beAgentBtn" @click="chooseType(2)">付费升级</button>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <button class="agentBtn" @click="upgradeVisible = false">取 消</button>
        <button class="agentBtn" @click="agentUpgrades()">确 定</button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import { agentUpgrade, checkUserAgent } from "@/api/api";
export default {
  data() {
    return {
      uid: "",
      level: "",
      type: "",
      username: "",
      upgradeVisible: false,
      agentData: [],
      isAgent: null,
    };
  },
  methods: {
    checkUserAgents() {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      if (uid) {
        checkUserAgent(token, uid).then((res) => {
          if (res.data.code === 200) {
            this.agentData = res.data.data;
            this.isAgent = 1;
            loading.close();
          } else {
            // this.$message({
            //   type: "error",
            //   message: res.data.msg,
            // });
            this.isAgent = 0;
            loading.close();
          }
        });
      }
      this.isAgent = 0;
      loading.close();
    },
    chooseType(type) {
      this.type = type;
      this.agentUpgrades();
    },
    openUpGradeDialog(level) {
      const token = localStorage.getItem("token");
      if (token) {
        if (level === 0) {
          this.$router.push("/agentLogin");
        }
        this.upgradeVisible = true;
        this.level = level;
      } else {
        this.$message({
          type: "error",
          message: "请先登录！",
        });
        setTimeout(() => {
          this.$router.push("/login");
        }, 500);
      }
    },
    agentUpgrades() {
      const loading = this.$loading({
        lock: true,
        text: "正在处理",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      if (this.type) {
        const token = localStorage.getItem("token");
        const uid = this.uid;
        agentUpgrade(token, uid, this.level, this.type).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              type: "success",
              message: "升级成功！",
            });
            this.upgradeVisible = false;
            loading.close();
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
            loading.close();
          }
        });
      } else {
        this.$message({
          type: "error",
          message: "请选择升级方式",
        });
      }
    },
  },
  created() {
    this.uid = localStorage.getItem("uid");
    this.username = localStorage.getItem("username");
    this.checkUserAgents();
  },
};
</script>

<style>
.agentBanner {
  background-image: url("@/assets/banner5.jpg");
  height: 350px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
}

.el-col-lg-5 {
  width: 20%;
}

.agentBtn {
  background-color: white;
  color: #cb1414;
  border: 1px #cb1414 solid;
  padding: 10px 20px;
  transition: all 0.2s ease;
}

.agentBtn:hover {
  color: white;
  background-color: #cb1414;
}

.beAgentBtn {
  background-color: #cb1414;
  color: white;
  border: 1px #cb1414 solid;
  padding: 10px 20px;
  transition: all 0.2s ease;
}

.beAgentBtn:hover {
  background-color: white;
  color: #cb1414;
}

.agentTitle {
  text-align: center;
  margin: 0 150px;
}

@media screen and (max-width: 768px) {
  .agentTitle {
    margin: 0;
  }
  .partner {
    margin-bottom: 10px;
    width: 50%;
  }
  .partner > .el-col-xs-24 {
    width: 100% !important;
  }
  .block::before {
    content: "";
    display: block;
    height: 29px;
  }
  .el-card {
    margin-bottom: 10px;
  }
}

.el-radio__input.is-checked + .el-radio__label {
  color: #cb1414;
}

@media screen and (min-width: 1900px) {
  .block {
    display: none;
  }
}
</style>
