<template>
  <div>
    <div>
      <!-- <el-select
        v-model="menu"
        @change="handleSelectChange(menu)"
        placeholder="请选择订单类型"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
      <el-menu default-active="1" class="el-menu-demo" mode="horizontal">
        <el-menu-item index="1" @click="(menu = 1), handleSelectChange(menu)"
          >游戏产品</el-menu-item
        >
        <el-menu-item index="2" @click="(menu = 2), handleSelectChange(menu)"
          >云服务器</el-menu-item
        >
        <el-menu-item index="3" @click="(menu = 3), handleSelectChange(menu)"
          >定制服务</el-menu-item
        >
      </el-menu>
    </div>
    <!-- 游戏订单类型表格 -->
    <div v-if="menu === 1 || menu === null">
      <el-table :data="orderList" v-loading="loading" style="width: 100%">
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="game_type" label="游戏类型"> </el-table-column>
        <el-table-column prop="orderno" label="订单号"> </el-table-column>
        <el-table-column prop="create_time" label="创建时间">
          <template slot-scope="scope">
            {{ formatDate(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="payamount" label="支付金额"> </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" type="danger">异常</el-tag>
            <el-tag v-if="scope.row.status === 1" type="success">正常</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="订单描述" width="200">
          <template slot-scope="scope">
            <!-- YonoGames游戏描述 -->
            <div v-if="scope.row.game_type == 'YonoGames'">
              <span v-if="getGameList(scope.row.gameList).length > 10">
                完整版YonoGames购买
              </span>
              <div
                v-else
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 200px;
                "
              >
                子游戏购买：
                <span
                  v-for="(item, index) in getGameList(scope.row.gameList)"
                  :key="index"
                >
                  <span>{{ item }}</span>
                </span>
              </div>
            </div>
            <div v-else-if="scope.row.game_type == 'CokeJogo'">
              <span>CokeJogo完整版游戏购买</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="子游戏">
          <template slot-scope="scope">
            <el-button
              disabled
              v-if="scope.row.game_type == 'CokeJogo'"
              type="text"
              >暂不支持查看</el-button
            >
            <el-button
              v-else
              type="text"
              @click="checkGameList(scope.row.gameList)"
              >查看子游戏</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        style="margin-top: 10px; float: right; margin-bottom: 20px"
        background
        layout="prev, pager, next, jumper"
        :total="total"
        :page-size="pageSize"
        :current-page="page"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <!-- 云服务器订单类型表格 -->
    <div v-if="menu === 2">
      <el-table :data="serverOrderList" v-loading="loading" style="width: 100%">
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="orderno" label="订单号"> </el-table-column>
        <el-table-column prop="region" label="服务器区域"> </el-table-column>
        <el-table-column prop="amount" label="订单金额"> </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" type="danger">待支付</el-tag>
            <el-tag v-if="scope.row.status === 1" type="success">已支付</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="订单描述">
          <template slot-scope="scope">
            <el-button type="text" @click="checkOrderInfo(scope.row)"
              >查看订单描述</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="createtime" label="创建时间">
          <template slot-scope="scope">
            {{ formatDate(scope.row.createtime) }}
          </template>
        </el-table-column>
        <el-table-column prop="expired" label="到期时间">
          <template slot-scope="scope">
            <span v-if="scope.row.expired">{{
              formatDate(scope.row.expired)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              v-if="scope.row.status === 0"
              @click="openRenewalServerDialog(scope.row)"
              >支付</el-button
            >
            <el-button type="primary" disabled v-if="scope.row.status === 1"
              >支付</el-button
            >
            <!-- <el-button type="warning" disabled v-if="scope.row.status === 0"
              >续费</el-button
            >
            <el-button v-else @click="renewalCustoms(scope.row)" type="warning"
              >续费</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        style="margin-top: 10px; float: right; margin-bottom: 20px"
        background
        layout="prev, pager, next, jumper"
        :total="total"
        :page-size="pageSize"
        :current-page="page"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <!-- 定制服务订单类型表格 -->
    <div v-if="menu === 3">
      <el-table :data="customOrderList" v-loading="loading" style="width: 100%">
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="orderno" label="订单号"> </el-table-column>
        <el-table-column prop="product" label="游戏产品"> </el-table-column>
        <el-table-column prop="required" label="技术要求">
          <template slot-scope="scope">
            <span
              v-for="(item, index) in getGameList(scope.row.required)"
              :key="index"
            >
              {{ item }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="single_month_amount" label="单月价格">
        </el-table-column>
        <el-table-column prop="service_time" label="服务时长">
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" type="danger">待支付</el-tag>
            <el-tag v-if="scope.row.status === 1" type="success">已支付</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createtime" label="创建时间">
          <template slot-scope="scope">
            {{ formatDate(scope.row.createtime) }}
          </template>
        </el-table-column>
        <el-table-column prop="endtime" label="到期时间">
          <template slot-scope="scope">
            <span v-if="scope.row.endtime">{{
              formatDate(scope.row.endtime)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              v-if="scope.row.status === 0"
              @click="openRenewalCustomDialog(scope.row)"
              >支付</el-button
            >
            <el-button type="primary" disabled v-if="scope.row.status === 1"
              >支付</el-button
            >
            <!-- <el-button v-if="scope.row.status === 0" type="warning" disabled
              >续费</el-button
            >
            <el-popconfirm
              v-else
              title="确定要续费吗?"
              @confirm="
                renewalCustomss(
                  scope.row.orderno,
                  scope.row.single_month_amount,
                  scope.row.service_time
                )
              "
            >
              <el-button
                slot="reference"
                type="warning"
                style="margin-left: 10px"
                >续费</el-button
              >
            </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        style="margin-top: 10px; float: right; margin-bottom: 20px"
        background
        layout="prev, pager, next, jumper"
        :total="total"
        :page-size="pageSize"
        :current-page="page"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <!-- 查看子游戏 -->
    <el-dialog title="查看子游戏" :visible.sync="checkGameDialog" width="80%">
      <!-- <el-tag
        v-for="(item, index) in gameList"
        :key="index"
        style="margin: 5px"
      >
        {{ item }}
      </el-tag> -->
      <div style="display: flex; flex-wrap: wrap; gap: 10px">
        <!-- 遍历 gameList，根据名称找到对应游戏对象 -->
        <div v-for="gameTitle in gameList" :key="gameTitle">
          <!-- 找到 game 数组中与 gameTitle 匹配的游戏 -->
          <img
            :src="game.find((item) => item.title === gameTitle)?.img || ''"
            alt=""
            style="
              width: 50px;
              height: 50px;
              object-fit: cover;
              border-radius: 5px;
            "
          />
          <p
            style="
              margin: 0;
              font-size: 14px;
              color: #333;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 60px;
            "
          >
            {{ gameTitle }}
          </p>
        </div>
      </div>
    </el-dialog>
    <!-- 订单描述 -->
    <el-dialog title="查看订单描述" :visible.sync="orderInfoDialog" width="30%">
      <el-descriptions :title="serverInfo.orderno + ' 服务器信息'">
        <el-descriptions-item label="服务器用户名">{{
          serverInfo.username
        }}</el-descriptions-item>
        <el-descriptions-item label="服务器区域">{{
          serverInfo.region
        }}</el-descriptions-item>
        <el-descriptions-item label="内存大小(M)">{{
          serverInfo.ram_size
        }}</el-descriptions-item>
        <el-descriptions-item label="CPU核心数">{{
          serverInfo.cpu_core
        }}</el-descriptions-item>
        <el-descriptions-item label="网络大小(M)">{{
          serverInfo.network_size
        }}</el-descriptions-item>
        <el-descriptions-item label="硬盘大小(GB)">{{
          serverInfo.disk_size
        }}</el-descriptions-item>
        <el-descriptions-item label="操作系统类型名称">{{
          serverInfo.system_type
        }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <!-- 云服务器续费弹窗 -->
    <el-dialog
      :title="'云服务器支付：' + renewalServerOrderno"
      v-loading="loading"
      :visible.sync="renewalServerDialog"
      width="30%"
    >
      <span>请选择服务支付时长</span>
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :lg="6"
          :offset="0"
          v-for="item in time"
          :key="item.id"
          style="margin: 10px 0"
        >
          <div
            @click="
              chooseServiceTime(item.time, item.discountValue, item.month)
            "
            :style="{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#ffeae6',
              borderRadius: '10px',
              border:
                serviceTime === item.time
                  ? '1px #ff0000 solid'
                  : '1px #666666 solid',
              boxShadow:
                serviceTime === item.time
                  ? '0 0 20px #ff0000'
                  : '0 0 0px #909399',
              transition: 'all 0.3s ease',
              lineHeight: '40px',
            }"
          >
            <i
              class="el-icon-caret-right"
              style="color: #1f62b5; font-size: 15px"
            ></i>
            <p style="font-size: 15px; margin: 8px">
              {{ item.time }}
            </p>

            <div
              v-if="item.discount != '无折扣'"
              style="
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 0;
                border-top: 50px solid red;
                border-left: 50px solid transparent;
                border-radius: 0 10px 0 0;
              "
            >
              <span
                style="
                  position: absolute;
                  top: -40px;
                  right: -30px;
                  left: -30px;
                  transform: rotate(45deg);
                  color: white;
                  font-size: 14px;
                "
              >
                {{ item.discount }}
              </span>
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="float: right">
        <p v-if="discountValue > 0">
          应付价格：<span style="color: red; font-weight: bolder">{{
            renewalServerAmount * renewaMonth
          }}</span>
          优惠金额：<span style="color: red; font-weight: bolder">
            {{ renewalServerAmount * renewaMonth * discountValue }}
          </span>
          实际支付金额：<span style="color: red; font-weight: bolder">
            {{
              renewalServerAmount * renewaMonth -
              renewalServerAmount * renewaMonth * discountValue
            }}
          </span>
        </p>
        <p v-else>
          应付价格：<span style="color: red; font-weight: bolder">{{
            renewalServerAmount
          }}</span>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="renewalServers()">立即支付</button>
      </span>
    </el-dialog>
    <!-- 定制服务支付弹窗 -->
    <el-dialog
      :title="'定制服务支付：' + renewalCustomOrderno"
      v-loading="loading"
      :visible.sync="renewalCustomDialog"
      width="30%"
    >
      <span>请选择服务支付时长</span>
      <el-row :gutter="20">
        <el-col
          :xs="24"
          :lg="6"
          :offset="0"
          v-for="item in time"
          :key="item.id"
          style="margin: 10px 0"
        >
          <div
            @click="
              chooseServiceTime(item.time, item.discountValue, item.month)
            "
            :style="{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#ffeae6',
              borderRadius: '10px',
              border:
                serviceTime === item.time
                  ? '1px #ff0000 solid'
                  : '1px #666666 solid',
              boxShadow:
                serviceTime === item.time
                  ? '0 0 20px #ff0000'
                  : '0 0 0px #909399',
              transition: 'all 0.3s ease',
              lineHeight: '40px',
            }"
          >
            <i
              class="el-icon-caret-right"
              style="color: #1f62b5; font-size: 15px"
            ></i>
            <p style="font-size: 15px; margin: 8px">
              {{ item.time }}
            </p>

            <div
              v-if="item.discount != '无折扣'"
              style="
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 0;
                border-top: 50px solid red;
                border-left: 50px solid transparent;
                border-radius: 0 10px 0 0;
              "
            >
              <span
                style="
                  position: absolute;
                  top: -40px;
                  right: -30px;
                  left: -30px;
                  transform: rotate(45deg);
                  color: white;
                  font-size: 14px;
                "
              >
                {{ item.discount }}
              </span>
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="float: right">
        <p v-if="discountValue > 0">
          应付价格：<span style="color: red; font-weight: bolder">{{
            renewalCustomAmount * renewaMonth
          }}</span>
          优惠金额：<span style="color: red; font-weight: bolder">
            {{ renewalCustomAmount * renewaMonth * discountValue }}
          </span>
          实际支付金额：<span style="color: red; font-weight: bolder">
            {{
              renewalCustomAmount * renewaMonth -
              renewalCustomAmount * renewaMonth * discountValue
            }}
          </span>
        </p>
        <p v-else>
          应付价格：<span style="color: red; font-weight: bolder">{{
            renewalCustomAmount
          }}</span>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="renewalCustoms()">立即支付</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCustomOrder,
  getServerOrder,
  getUserOrder,
  renewalCustom,
  renewalServer,
  renewalCloudServer,
} from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      menu: null,
      options: [
        {
          value: 1,
          label: "游戏订单类型",
        },
        {
          value: 2,
          label: "云服务器订单类型",
        },
        {
          value: 3,
          label: "定制服务订单类型",
        },
      ],
      orderList: [],
      gameList: "",
      checkGameDialog: false,
      game: [
        {
          id: 1,
          title: "Alibaba",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/Alibaba.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Alibaba.mp4",
          showFull: false,
        },
        {
          id: 2,
          title: "Archer",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/Archer.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Archer.mp4",
          showFull: false,
        },
        {
          id: 3,
          title: "G_Cleopatra",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/G_Cleopatra.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/G_Cleopatra.mp4",
          showFull: false,
        },
        {
          id: 4,
          title: "Lamp Of Aladdin",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/LampOfAladdin.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/LampOfAladdin.mp4",
          showFull: false,
        },
        {
          id: 5,
          title: "Power Of TheKraken",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/PowerOfTheKraken.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/PowerOfTheKraken.mp4",
          showFull: false,
        },
        {
          id: 6,
          title: "Regal Tiger",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/RegalTiger.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/RegalTiger.mp4",
          showFull: false,
        },
        {
          id: 7,
          title: "Sinbad",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/Sinbad.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Sinbad.mp4",
          showFull: false,
        },
        {
          id: 8,
          title: "Sky Garden",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/Skygarden.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/Skygarden.mp4",
          showFull: false,
        },
        {
          id: 10,
          title: "Sphinx",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/Sphinx.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Sphinx.mp4",
          showFull: false,
        },
        {
          id: 11,
          title: "The Legend Of Dragon",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/TheLegendOfDragon.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/TheLegendOfDragon.mp4",
          showFull: false,
        },
        {
          id: 12,
          title: "Xerxes",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/Xerxes.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Xerxes.mp4",
          showFull: false,
        },
        {
          id: 13,
          title: "Ander Bahar",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/11.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/AnderBahar.mp4",
          showFull: false,
        },
        {
          id: 14,
          title: "Crash",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/12.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Crash.mp4",
          showFull: false,
        },
        {
          id: 15,
          title: "Jhandi Munda",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/13.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/JhandiMunda.mp4",
          showFull: false,
        },
        {
          id: 16,
          title: "Horse Racing",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/14.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/HorseRacing.mp4",
          showFull: false,
        },
        {
          id: 17,
          title: "Wingo Lottery",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/15.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/WingoLottery.mp4",
          showFull: false,
        },
        {
          id: 18,
          title: "Fortune Wheel",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/16.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/FortuneWheel.mp4",
          showFull: false,
        },
        {
          id: 19,
          title: "Dragon Tiger",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/17.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/DragonTiger.mp4",
          showFull: false,
        },
        {
          id: 20,
          title: "Roulette",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/18.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Roulette.mp4",
          showFull: false,
        },
        {
          id: 21,
          title: "Baccarat",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/19.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Baccarat.mp4",
          showFull: false,
        },
        {
          id: 22,
          title: "7 Up Down",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/20.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/7UpDown.mp4",
          showFull: false,
        },
        {
          id: 23,
          title: "Aladdin's Blessing",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/21.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/Aladdin'sBlessing.mp4",
          showFull: false,
        },
        {
          id: 24,
          title: "Black Jack",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/255.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/BlackJack.mp4",
          showFull: false,
        },
        {
          id: 26,
          title: "Domin",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/265.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Domin.mp4",
          showFull: false,
        },
        {
          id: 27,
          title: "LUDO",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/269.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/LUDO.mp4",
          showFull: false,
        },
        {
          id: 28,
          title: "UNO",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/287.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/UNO.mp4",
          showFull: false,
        },
        {
          id: 29,
          title: "Teen Patti",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/291.png"),
          video:
            "https://api.jisuqipai.online/uploads/video/game/TeenPatti.mp4",
          showFull: false,
        },
        {
          id: 30,
          title: "Rummy",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/292.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Rummy.mp4",
          showFull: false,
        },
        {
          id: 31,
          title: "Poker",
          info: "将传统的印度家庭纸牌游戏带到在线桌上。玩积分拉米纸牌并赢取金币！加入每日桌游和锦标赛以及沙龙室，与您的朋友一起玩！",
          img: require("@/assets/game/293.png"),
          video: "https://api.jisuqipai.online/uploads/video/game/Poker.mp4",
          showFull: false,
        },
      ],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 10,
      serverOrderList: [],
      customOrderList: [],
      orderInfoDialog: false,
      serverInfo: [],
      renewalServerOrderno: "",
      renewalServerDialog: false,
      renewalServerAmount: "",
      serviceTime: "",
      discountValue: "",
      renewaMonth: "",
      time: [
        {
          id: 1,
          time: "1个月",
          discount: "无折扣",
          discountValue: 0,
          month: 1,
        },
        {
          id: 2,
          time: "3个月",
          discount: "9折",
          discountValue: 0.1,
          month: 3,
        },
        {
          id: 3,
          time: "6个月",
          discount: "8折",
          discountValue: 0.2,
          month: 6,
        },
        {
          id: 4,
          time: "12个月",
          discount: "7折",
          discountValue: 0.3,
          month: 12,
        },
      ],
      renewalCustomOrderno: "",
      renewalCustomDialog: false,
      renewalCustomAmount: "",
    };
  },
  methods: {
    // 使用正则表达式匹配数字
    extractNumber(str) {
      if (typeof str !== "string") {
        console.error("extractNumber: 输入的值不是字符串", str); // 错误日志
        return null; // 返回 null，避免抛出错误
      }
      const match = str.match(/^\d+/); // 匹配以数字开头的部分
      return match ? parseInt(match[0], 10) : null; // 转换为整数
    },
    // 定制服务续费
    renewalCustomss(orderno, amount, month) {
      console.log(month);
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      renewalCustom(
        token,
        uid,
        orderno,
        amount,
        this.extractNumber(month)
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("续费成功！");
          this.getCustomOrders();
          this.renewalCustomDialog = false;
          loading.close();
        } else {
          this.$message.error("续费失败！" + res.data.msg);
          loading.close();
        }
      });
    },
    renewalCloudServers(row) {
      const loading = this.$loading({
        lock: true,
        text: "正在加载",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      renewalCloudServer(token, row.orderno, uid).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("续费成功！");
          this.getServerOrders();
          loading.close();
          return;
        }
        this.$message.error("续费失败！" + res.data.msg);
        loading.close();
      });
    },
    renewalCustoms() {
      if (
        (this.renewalCustomOrderno, this.renewalCustomAmount, this.renewaMonth)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在加载",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const token = localStorage.getItem("token");
        const uid = localStorage.getItem("uid");
        renewalCustom(
          token,
          uid,
          this.renewalCustomOrderno,
          this.renewalCustomAmount * this.renewaMonth -
            this.renewalCustomAmount * this.renewaMonth * this.discountValue,
          this.renewaMonth
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("续费成功！");
            this.getCustomOrders();
            this.renewalCustomDialog = false;
            loading.close();
          } else {
            this.$message.error("续费失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请选择续费时长！");
      }
    },
    renewalServers() {
      if (
        (this.renewalServerOrderno, this.renewalServerAmount, this.renewaMonth)
      ) {
        const loading = this.$loading({
          lock: true,
          text: "正在加载",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const token = localStorage.getItem("token");
        const uid = localStorage.getItem("uid");
        renewalServer(
          token,
          uid,
          this.renewalServerOrderno,
          this.renewalServerAmount * this.renewaMonth -
            this.renewalServerAmount * this.renewaMonth * this.discountValue,
          this.renewaMonth
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("续费成功！");
            this.getServerOrders();
            this.renewalServerDialog = false;
            loading.close();
          } else {
            this.$message.error("续费失败！" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("请选择续费时长！");
      }
    },
    openRenewalCustomDialog(row) {
      this.renewalCustomOrderno = row.orderno;
      this.renewalCustomAmount = row.single_month_amount;
      this.renewalCustomDialog = true;
    },
    openRenewalServerDialog(row) {
      this.renewalServerOrderno = row.orderno;
      this.renewalServerAmount = row.amount;
      this.renewalServerDialog = true;
    },
    chooseServiceTime(time, discount, month) {
      this.serviceTime = time;
      this.discountValue = discount;
      this.renewaMonth = month;
    },
    getGameList(gameList) {
      if (typeof gameList === "string") {
        try {
          return JSON.parse(gameList); // 解析字符串为数组
        } catch (error) {
          console.error("gameList 解析失败:", error);
          return []; // 如果解析失败，返回空数组
        }
      }
      return Array.isArray(gameList) ? gameList : []; // 如果是数组，直接返回
    },
    checkOrderInfo(row) {
      this.serverInfo = row;
      this.orderInfoDialog = true;
    },
    handleSelectChange(menu) {
      if (menu === 2) {
        this.getServerOrders();
      } else if (menu === 3) {
        this.getCustomOrders();
      }
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    getCustomOrders() {
      this.loading = true;
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      getCustomOrder(token, uid, this.page, this.pageSize).then((res) => {
        if (res.data.code === 200) {
          this.customOrderList = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error("获取订单失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    getServerOrders() {
      this.loading = true;
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      getServerOrder(token, uid, this.page, this.pageSize).then((res) => {
        if (res.data.code === 200) {
          this.serverOrderList = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error("获取订单失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    getUserOrders() {
      this.loading = true;
      const token = localStorage.getItem("token");
      const uid = localStorage.getItem("uid");
      getUserOrder(token, uid, this.page, this.pageSize).then((res) => {
        if (res.data.code === 200) {
          this.orderList = res.data.data.data;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error("获取订单失败！" + res.data.msg);
          this.loading = false;
        }
      });
    },
    // getUserOrders() {
    //   getUserOrder(
    //     localStorage.getItem("token"),
    //     localStorage.getItem("uid")
    //   ).then((res) => {
    //     if (res.data.code === 200) {
    //       this.orderList = res.data.data;
    //     } else {
    //       this.$message.error("获取用户订单失败！" + res.data.msg);
    //     }
    //   });
    // },
    checkGameList(gameListString) {
      this.gameList = JSON.parse(gameListString);
      this.checkGameDialog = true;
    },
  },
  created() {
    this.getUserOrders();
  },
};
</script>

<style></style>
