<template>
  <div style="margin: 20px 150px 0 150px" v-loading="loading">
    <el-row
      v-for="item in data"
      :key="item.id"
      style="border-bottom: 0.5px solid #666666"
    >
      <el-col
        :span="6"
        :offset="0"
        style="background-color: #f8f8f8; padding: 16px"
      >
        <p style="margin: 0">{{ item.left_title }}</p>
        <p style="margin: 0; color: #666666; font-size: 13px">
          {{ item.left_desc }}
        </p>
      </el-col>
      <el-col
        :span="9"
        :offset="0"
        style="padding: 16px; background-color: white"
      >
        <el-row style="text-align: center">
          <el-col :span="6" :offset="0">
            <p style="margin: 0">{{ item.cpu_cores }}核</p>
            <p style="margin: 0; color: #666666; font-size: 13px">CPU</p>
          </el-col>
          <el-col :span="6" :offset="0">
            <p style="margin: 0">{{ item.ram_size }}G</p>
            <p style="margin: 0; color: #666666; font-size: 13px">内存</p>
          </el-col>
          <el-col :span="6" :offset="0">
            <p style="margin: 0">{{ item.broadband }}M</p>
            <p style="margin: 0; color: #666666; font-size: 13px">
              BGP国际带宽
            </p>
          </el-col>
          <el-col :span="6" :offset="0">
            <p style="margin: 0">{{ item.disk_size }}G</p>
            <p style="margin: 0; color: #666666; font-size: 13px">
              高速SSD硬盘
            </p>
          </el-col>
        </el-row>
      </el-col>
      <el-col
        :span="9"
        :offset="0"
        style="background-color: white; padding: 8px"
      >
        <el-row>
          <el-col :span="8" :offset="0">
            <!-- 月价格 -->
            <div
              :style="getPriceStyle(item.id, 'monthly')"
              @click="selectPrice(item.id, 'monthly', item.month_mark_price)"
              style="
                text-align: center;
                color: #cb1414;
                border: 1px #cb1414 solid;
                display: inline-block;
                padding: 5px 30px;
              "
            >
              <p style="margin: 0; font-size: 13px">
                <span style="font-size: 20px">{{ item.month_mark_price }}</span
                >元/月
              </p>
              <p style="margin: 0; font-size: 13px">立省50元</p>
            </div>
          </el-col>
          <el-col :span="9" :offset="0">
            <!-- 年价格 -->
            <div
              :style="getPriceStyle(item.id, 'yearly')"
              @click="selectPrice(item.id, 'yearly', item.year_mark_price)"
              style="
                text-align: center;
                color: #cb1414;
                border: 1px #cb1414 solid;
                display: inline-block;
                padding: 5px 30px;
              "
            >
              <p style="margin: 0; font-size: 13px">
                <span style="font-size: 20px">{{ item.year_mark_price }}</span
                >元/年
              </p>
              <p style="margin: 0; font-size: 13px">立省1500元</p>
            </div>
          </el-col>
          <el-col :span="7" :offset="0">
            <button class="serverButton" @click="buyDialog(item)">
              立即购买
            </button>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 服务器配置 -->
    <el-dialog
      title="服务器配置"
      :visible.sync="chooseSystemVisible"
      width="30%"
      center
    >
      <el-form label-position="left" inline label-width="140px">
        <el-form-item label="选择操作系统">
          <el-radio-group v-model="systemInfo.op_system">
            <el-radio label="Linux - CentOS 7">Linux - CentOS 7</el-radio>
            <!-- <el-radio :label="2">Windows</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="请输入服务器用户名">
          <el-input v-model="serverUsername"></el-input>
        </el-form-item>
        <el-form-item label="请输入服务器密码">
          <el-input v-model="serverPassword"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button class="serverButton" @click="randomPassword()">
          生成随机密码
        </button>
        <button class="serverButton" @click="chooseSystemVisible = false">
          取 消
        </button>
        <button class="serverButton" @click="serverOrders()">确 定</button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getServerList, index, serverOrder } from "@/api/api";
export default {
  data() {
    return {
      id: parseInt(this.$route.params.id),
      text: "",
      selectedPrice: { id: null, type: null }, // 用于跟踪选中的服务器及价格类型
      chooseSystemVisible: false,
      serverUsername: "",
      serverPassword: "",
      servers: [],
      selectedType: "",
      selectedId: "",
      system: "",
      systemInfo: "",
      data: [],
      loading: false,
      serverPrice: 0,
    };
  },
  methods: {
    getServerLists() {
      this.loading = true;
      getServerList(this.id).then((res) => {
        if (res.data.code === 200) {
          this.data = res.data.data;
          this.loading = false;
        } else {
          this.$message.error("获取信息失败" + res.data.msg);
          this.loading = false;
        }
      });
    },
    serverOrders() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const uid = localStorage.getItem("uid");
      const token = localStorage.getItem("token");
      console.log(this.systemInfo);
      serverOrder(
        token,
        uid,
        this.text,
        this.systemInfo.ram_size,
        this.systemInfo.cpu_cores,
        this.systemInfo.broadband,
        this.systemInfo.disk_size,
        this.systemInfo.op_system,
        this.serverUsername,
        this.serverPassword,
        this.serverPrice,
        this.selectedType
      )
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("下单成功！");
            this.chooseSystemVisible = false;
            loading.close();
          } else {
            this.$message.error("下单失败！" + res.msg);
            loading.close();
          }
        })
        .catch((err) => {
          this.$message.error("出现错误！" + err.msg);
          loading.close();
        });
    },
    buyDialog(row) {
      if (this.serverPrice === 0) {
        this.$message.warning("请选择服务器价格！");
        return;
      } else if (row.id != this.selectedId) {
        this.$message.warning("请选择正确的服务器区域！");
        return;
      }
      this.systemInfo = row;
      this.chooseSystemVisible = true;
      console.log(row.left_title);
    },
    randomPassword() {
      this.serverPassword = this.generateRandomString(8);
    },
    generateRandomString(length) {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },
    serverArea() {
      switch (this.id) {
        case 1:
          this.text = "印度";
          break;
        case 2:
          this.text = "印尼";
          break;
        case 3:
          this.text = "菲律宾";
          break;
        case 4:
          this.text = "马来西亚";
          break;
        case 5:
          this.text = "越南";
          break;
        case 6:
          this.text = "巴西";
          break;
        case 7:
          this.text = "美国";
          break;
        default:
          this.text = "未知区域"; // 添加一个默认值
      }
    },
    getPriceStyle(id, type) {
      return {
        backgroundColor:
          this.selectedPrice.id === id && this.selectedPrice.type === type
            ? "#ffefef"
            : "#ffffff",
      };
    },
    selectPrice(id, type, price) {
      this.selectedPrice = { id, type }; // 记录选中的服务器ID和价格类型
      this.selectedType = type;
      this.selectedId = id;
      this.serverPrice = price;
    },
    indexs() {
      const token = localStorage.getItem("token");
      index(token).then((res) => {
        if (res.data.code === 401) {
          this.$message.error("请重新登录！");
          localStorage.removeItem("username");
          localStorage.removeItem("nickname");
          localStorage.removeItem("mobile");
          localStorage.removeItem("avatar");
          localStorage.removeItem("token");
          localStorage.setItem("isLogin", "false");
          setTimeout(() => {
            this.$router.push("/login");
          }, 300);
        }
      });
    },
  },
  created() {
    const token = localStorage.getItem("token");
    if (!token) {
      this.$message.error("请先登录！");
      setTimeout(() => {
        this.$router.push("/login");
      }, 300);
    }
    this.serverArea();
    this.indexs();
    this.getServerLists();
  },
};
</script>

<style>
.serverButton {
  background-color: #cb1414;
  color: white;
  border: 0px;
  padding: 8px 18px;
  border: 1px #cb1414 solid;
  margin-top: 13px;
  transition: all 0.3s ease;
}
.serverButton:hover {
  background-color: white;
  color: #cb1414;
}
</style>
