<template>
  <div>
    <el-breadcrumb>
      <el-breadcrumb-item>
        <span>我的位置：</span>
        <span @click="chooseMenu(1)" style="cursor: pointer" class="clickable">
          个人中心</span
        >
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span @click="chooseMenu(5)" style="cursor: pointer" class="clickable"
          >我的产品</span
        >
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span @click="chooseMenu(104)" style="cursor: pointer" class="clickable"
          >{{ GameType }}：{{ GameOrder }}</span
        >
      </el-breadcrumb-item>
    </el-breadcrumb>
    <p style="margin: 10px 0">游戏服务控制</p>
    <div>
      <el-row :gutter="20">
        <el-col :span="6" :offset="0">
          <el-button type="primary" style="width: 100%">启动</el-button>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-button type="danger" style="width: 100%">停止</el-button>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-button type="warning" style="width: 100%">重启</el-button>
        </el-col>
        <el-col :span="6" :offset="0">&nbsp;</el-col>
      </el-row>
    </div>
    <p style="margin: 10px 0">游戏管理</p>
    <div>
      <el-row :gutter="20">
        <el-col :span="6" :offset="0">
          <el-button type="primary" style="width: 100%">游戏后台管理</el-button>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-button type="danger" style="width: 100%">进入H5游戏</el-button>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-button type="warning" style="width: 100%"
            >下载Android客户端</el-button
          >
        </el-col>
        <el-col :span="6" :offset="0">
          <el-button type="success" style="width: 100%"
            >下载iOS客户端</el-button
          >
        </el-col>
      </el-row>
    </div>
    <p style="margin: 10px 0">落地页管理</p>
    <div>
      <el-row :gutter="20">
        <el-col :span="6" :offset="0">
          <el-button type="primary" style="width: 100%">进入落地页</el-button>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-button type="danger" style="width: 100%">编辑落地页</el-button>
        </el-col>
        <el-col :span="6" :offset="0">&nbsp;</el-col>
        <el-col :span="6" :offset="0">&nbsp;</el-col>
      </el-row>
    </div>
    <p style="margin: 10px 0">代码管理</p>
    <div>
      <el-row :gutter="20">
        <el-col :span="6" :offset="0">
          <el-button type="primary" style="width: 100%">编辑代码</el-button>
        </el-col>
        <el-col :span="6" :offset="0">&nbsp;</el-col>
        <el-col :span="6" :offset="0">&nbsp;</el-col>
        <el-col :span="6" :offset="0">&nbsp;</el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    GameType: {
      type: String,
      default: "",
    },
    GameOrder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    chooseMenu(data) {
      this.$emit("custom-event", data);
    },
  },
  created() {},
};
</script>

<style></style>
